import Dashboard from "@material-ui/icons/Dashboard";
import Public from "../views/Public/public.jsx";
import UnderConstructionPage from "../views/UnderConstruction/UnderConstruction.jsx";

const publicRoutes = [
  {
    path: '/:hash',
    sidebarName: 'CARSIS',
    navbarName: 'Nástenka',
    icon: Dashboard,
    component: Public
  },
  {
    path: '/',
    sidebarName: 'CARSIS',
    navbarName: 'Nástenka',
    icon: Dashboard,
    component: UnderConstructionPage
  }
];

export default publicRoutes;
