import {customersConstants} from '../constants/customers.constants';
import {ordersConstants} from "../constants/orders.constants";

export function Customers(state = {}, action) {
  switch (action.type) {
    case customersConstants.CUSTOMERS_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case customersConstants.CUSTOMERS_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.customers,
        data: action.data
      };
    case customersConstants.CUSTOMERS_LOAD_DATA_FAILURE:
      return {
        error: action.error
      };

    case customersConstants.CUSTOMERS_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.customers,
        detail: action.detail
      };
    case customersConstants.CUSTOMERS_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case customersConstants.CUSTOMERS_SET_SUBMENU:
      return {
        ...state,
        ...state.customers,
        submenu: action.submenu
      };

    case customersConstants.CUSTOMERS_RESET_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.customers,
        detail: action.detail
      };

    case customersConstants.CUSTOMERS_SET_DETAIL_ID:
      return {
        ...state,
        ...state.customers,
        detailId: action.detailId
      };

    case customersConstants.CUSTOMERS_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.customers,
        data: [...state.data, action.data],
        isSubmitting: false
      };
    default:
      return state
  }
}