/* eslint-disable */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import publicRoutes from '../../routes/public.jsx';
import { getProjectNameFromUrl } from '../../functions';
import { layout as carsis } from '../../variables/layouts/carsis.layout';
import { layout as personas } from '../../variables/layouts/personas.layout';
import { layout as therooms } from '../../variables/layouts/therooms.layout';
import { layout as iot } from '../../variables/layouts/iot.layout';
import { layout as webased } from '../../variables/layouts/webased.layout';

const switchRoutes = (
  <Switch>
    {publicRoutes.map((prop, key) => {
      if (prop.redirect) {
        console.log('verejna adresa');
        return <Redirect from={prop.path} to={prop.to} key={key}/>;
      }
      return <Route path={prop.path} component={prop.component} key={key}/>;
    })}
  </Switch>
);

class App extends React.Component {
  componentDidMount() {
    console.log('routing pre verejne adresy');
    switchRoutes;

    let project = getProjectNameFromUrl();
    let title = '';

    switch (true) {
      case project === carsis.name:
        title = carsis.name;
        break;
      case project === personas.name:
        title = personas.name;
        break;
      case project === therooms.name:
        title = therooms.name;
        break;
      case project === iot.name:
        title = iot.name;
        break;
      case project === webased.name:
        title = webased.name;
        break;
      default:
        title = 'webased';

    }
    document.title = title;

  }

  render() {
    return (
      <div>
        <h1 style={{textAlign: 'center'}}>Verejné URL adresy</h1>
      </div>
    );
  }
}

export default App;
