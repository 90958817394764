import {GET_ENVIRONMENT, LANG} from '../constants/index';
import {dashboardRoutes} from '../variables/dashboardRoutes';
import * as locales from '../locales/data.json';
import {vehiclesRoutes} from "../routes/vehicles.routes";

/**
 * Converts data source path to full url
 * @param {string} path - datasource
 * @returns {string} full url
 *
 */
export function getApiUrl(domainUrl, path) {
  let ext = GET_ENVIRONMENT === 'localhost' ? '.json' : '';
  let url = `${domainUrl}${path}${ext}`;
  return url
}

export function getSubdomain() {

  let host = window.location.host;
  let protocol = window.location.protocol;
  let parts = host.split(".");
  let subdomain = "";

  if (parts.length >= 3) {
    subdomain = parts[0];
    parts.splice(0, 1);
  }

  return subdomain;
}

export function getProjectNameFromUrl() {

  let host = window.location.host;
  let parts = host.split(".");
  let project = "";

  if (parts.length >= 3) {
    project = parts[1];
    parts.splice(0, 1);
  }

  if (project === 'andrascik') {
    project = 'therooms';
  }
  if (project === 'skusto') {
    project = 'iot';
  }
  if (project === 'webased') {
    project = 'webased';
  }
  return project ? project : 'carsis';
}

/**
 * Search for string in array
 * @param array
 * @param search
 * @returns {*}
 */
export function countMatchesInArray(array, search) {
  return array.filter(x => x === search).length;
}

/**
 * Search for particular permissions
 * @param permission
 * @returns {*}
 */
export function hasPermission(permission) {
  if (permission) {
    const user = JSON.parse(localStorage.getItem('user'));
    let count = user && user.permissions ? user.permissions.filter(x => x === permission).length : 0;
    return (count > 0);
  } else {
    return false;
  }
}

/**
 * Get language iso from url
 * @returns string
 */
export function getLangFromUrl() {
  return window.location.pathname.split('/')[1];
}

/**
 * Gets user info
 * @returns User
 */
export function getUserInfo() {
  return JSON.parse(localStorage.getItem('user'));
}

/**
 * Get route info
 * @param module
 * @param lang
 */
export function getRouteInfo(module, lang) {
  let route = '';
  dashboardRoutes.map((prop, key) => {
    route = prop[module] ? prop[module].path[lang] : '';
  });
  return route;
}

/**
 * Converts all numbers in object into strings, f.e. 12 to "12"
 * @param obj
 * @returns {any}
 */
export function convertNumbersInObjectToStrings(obj) {
  return JSON.parse(JSON.stringify(obj).replace(/\d+/g, function (match) {
    return `"${match}"`
  }));
}

/**
 * Converts all numbers in object into strings
 * @param obj
 * @returns {any}
 */
export function getNumbersFromObject(obj) {
  let numbers = [];
  JSON.stringify(obj).replace(/\d+/g, function (match) {
    numbers.push(match);
  });
  return numbers;
}

/**
 * Converts all numbers in object into strings, f.e. 12 to "12"
 * @param obj
 * @returns [array]
 */
export function getNumbersFromArrayAndConvertToInt(array) {
  let numbers = [];
  JSON.stringify(array).replace(/\d+/g, function (match) {
    numbers.push(parseInt(match));
  });
  return numbers;
}


/**
 * Locale translation string
 * @param string
 * @param lang
 * @param lowercase
 * @returns {string | *}
 */
export function translate(string, lang, lowercase = false) {
  if (locales && locales[lang] && locales[lang][string]) {
    let translation = locales[lang][string];
    let output = (translation) ? translation : string;
    return lowercase ? output.toLowerCase() : output;
  }
}

export function getTabUrl(module) {
  let tabname = window.location.pathname.split('/')[3];
  let out = '';
  vehiclesRoutes.map(e => {
      if (e[LANG] && e[LANG].tab) {
        if (e[LANG].tab === tabname) {
          out = `${e[LANG].translationId}`;
        }
      }
    }
  );
  console.log(out)
  return out;
}

export function getIdFromUrl() {
  let url = window.location.pathname;
  return parseInt(url.match(/(\d+)/g));
}

export function setOptionsFormat(array, valueKey, labelKey) {
  let options = [];
  array.forEach(function (obj) {
    options.push({'id': obj[valueKey], 'name': obj[labelKey]});
  });
  return options;
}
