import {iotValuesConstants} from '../constants/iotValues.constants';

export function IotValues(state = {}, action) {
  switch (action.type) {
    case iotValuesConstants.IOT_VALUES_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case iotValuesConstants.IOT_VALUES_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.IOT_VALUES,
        data: action.data
      };
    case iotValuesConstants.IOT_VALUES_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.IOT_VALUES,
        error: action.error
      };


    case iotValuesConstants.IOT_VALUES_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.IotValues,
        detail: action.detail
      };
    case iotValuesConstants.IOT_VALUES_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case iotValuesConstants.IOT_VALUES_LOAD_DETAIL_CHART_SUCCESS:
      return {
        ...state,
        ...state.IotValues,
        data: action.data
      };
    case iotValuesConstants.IOT_VALUES_LOAD_DETAIL_CHART_FAILURE:
      return {
        error: action.error
      };

    case iotValuesConstants.IOT_VALUES_SET_DETAIL_ID:
      return {
        ...state,
        ...state.IOT_VALUES,
        detailId: action.detailId
      };

    case iotValuesConstants.IOT_VALUES_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.IOT_VALUES,
        isSubmitting: action.isSubmitting
      };
    default:
      return state
  }
}