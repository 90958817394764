// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import UzivateliaIcon from "@material-ui/icons/GroupAdd";
import ServisnaKnizkaIcon from "@material-ui/icons/Assignment";
import ZakazkyIcon from "@material-ui/icons/Build";
import ZakazniciIcon from "@material-ui/icons/Person";
import VozidlaIcon from "@material-ui/icons/DirectionsCar";
import KalendarIcon from "@material-ui/icons/Today";
import UschovnaIcon from "@material-ui/icons/Toll";
import PriestoryIcon from "@material-ui/icons/AccountBalance";
import DiagnostikaIcon from "@material-ui/icons/MyLocation";
import InventarIcon from "@material-ui/icons/Ballot";
import NahradneDielyIcon from "@material-ui/icons/ShoppingCart";
import AttendanceIcon from '@material-ui/icons/TransferWithinAStation';
import RevenueIcon from '@material-ui/icons/EuroSymbol';
import WaitlistIcon from '@material-ui/icons/Timelapse';
import PermissionsIcon from '@material-ui/icons/Security';
import ClaimsIcon from '@material-ui/icons/SyncProblem';
import EvrcIcon from '@material-ui/icons/Payment';
import DriveIcon from '@material-ui/icons/AttachFile';
import AssetsIcon from '@material-ui/icons/Web';
import PostIcon from '@material-ui/icons/Mail';
import ChatIcon from '@material-ui/icons/Chat';
import EscapeRoomIcon from '@material-ui/icons/DirectionsRun';
import IotDevicesIcon from '@material-ui/icons/SettingsRemote';
import IotValuesIcon from '@material-ui/icons/SettingsRemote';
import SupportIcon from '@material-ui/icons/Help';
import ServicesIcon from '@material-ui/icons/Build';
import AlbumsIcon from '@material-ui/icons/PhotoAlbum';
import ModulesIcon from '@material-ui/icons/Apps';
import RemoteHelpIcon from '@material-ui/icons/HeadsetMic';
import Error403Icon from '@material-ui/icons/PanTool';
import PlayersIcon from '@material-ui/icons/Group';
import FlagSk from '../assets/svg/sk.svg';
import FlagEn from '../assets/svg/gb.svg';
import FlagDe from '../assets/svg/de.svg';
import FlagCs from '../assets/svg/cz.svg';
import FlagRu from '../assets/svg/ru.svg';

import ListIcon from '@material-ui/icons/ViewList';
import NewIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import HistoryIcon from '@material-ui/icons/History';
import CalendarIcon from '@material-ui/icons/Today';
import PollIcon from '@material-ui/icons/Poll';
import PieChartIcon from '@material-ui/icons/PieChart';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReaderIcon from '@material-ui/icons/Input';
import DownloadIcon from '@material-ui/icons/GetApp';
import AddExistingIcon from '@material-ui/icons/Link';
import UnlinkExistingIcon from '@material-ui/icons/LinkOff';

import MakeAcura from '../assets/img/makes/acura.jpg';
import MakeAlfaRomeo from '../assets/img/makes/alfaromeo.jpg';
import MakeAstonMartin from '../assets/img/makes/astonmartin.jpg';
import MakeAudi from '../assets/img/makes/audi.jpg';
import MakeBentley from '../assets/img/makes/bentley.jpg';
import MakeBmw from '../assets/img/makes/bmw.jpg';
import MakeBugatti from '../assets/img/makes/bugatti.jpg';
import MakeBuick from '../assets/img/makes/buick.jpg';
import MakeCadillac from '../assets/img/makes/cadillac.jpg';
import MakeChevrolet from '../assets/img/makes/chevrolet.jpg';
import MakeChrysler from '../assets/img/makes/chrysler.jpg';
import MakeCitroen from '../assets/img/makes/citroën.jpg';
import MakeDaewoo from '../assets/img/makes/daewoo.jpg';
import MakeDacia from '../assets/img/makes/dacia.jpg';
import MakeDodge from '../assets/img/makes/dodge.jpg';
import MakeFord from '../assets/img/makes/ford.jpg';
import MakeFerrari from '../assets/img/makes/ferrari.jpg';
import MakeFiat from '../assets/img/makes/fiat.jpg';
import MakeGeely from '../assets/img/makes/geely.jpg';
import MakeGenesis from '../assets/img/makes/genesis.jpg';
import MakeGm from '../assets/img/makes/gm.jpg';
import MakeGmc from '../assets/img/makes/gmc.jpg';
import MakeHonda from '../assets/img/makes/honda.jpg';
import MakeHyundai from '../assets/img/makes/hyundai.jpg';
import MakeIveco from '../assets/img/makes/iveco.jpg';
import MakeInfiniti from '../assets/img/makes/infiniti.jpg';
import MakeJaguar from '../assets/img/makes/jaguar.jpg';
import MakeJeep from '../assets/img/makes/jeep.jpg';
import MakeKia from '../assets/img/makes/kia.jpg';
import MakeKoenigsegg from '../assets/img/makes/koenigsegg.jpg';
import MakeLamborghini from '../assets/img/makes/lamborghini.jpg';
import MakeLandrover from '../assets/img/makes/landrover.jpg';
import MakeLada from '../assets/img/makes/lada.jpg';
import MakeLexus from '../assets/img/makes/lexus.jpg';
import MakeMan from '../assets/img/makes/man.jpg';
import MakeMaserati from '../assets/img/makes/maserati.jpg';
import MakeMazda from '../assets/img/makes/mazda.jpg';
import MakeMcLaren from '../assets/img/makes/mclaren.jpg';
import MakeMercedesBenz from '../assets/img/makes/mercedesbenz.jpg';
import MakeMini from '../assets/img/makes/mini.jpg';
import MakeMitsubishi from '../assets/img/makes/mitsubishi.jpg';
import MakeNissan from '../assets/img/makes/nissan.jpg';
import MakeOpel from '../assets/img/makes/opel.jpg';
import MakeOther from '../assets/img/makes/other.jpg';
import MakePagani from '../assets/img/makes/pagani.jpg';
import MakePeugeot from '../assets/img/makes/peugeot.jpg';
import MakePorsche from '../assets/img/makes/porsche.jpg';
import MakeRam from '../assets/img/makes/ram.jpg';
import MakeRenault from '../assets/img/makes/renault.jpg';
import MakeRollsRoyce from '../assets/img/makes/rollsroyce.jpg';
import MakeRover from '../assets/img/makes/rover.jpg';
import MakeSaab from '../assets/img/makes/saab.jpg';
import MakeSeat from '../assets/img/makes/seat.jpg';
import MakeSkoda from '../assets/img/makes/škoda.jpg';
import MakeSsangYong from '../assets/img/makes/ssangyong.jpg';
import MakeSubaru from '../assets/img/makes/subaru.jpg';
import MakeSuzuki from '../assets/img/makes/suzuki.jpg';
import MakeTata from '../assets/img/makes/tata.jpg';
import MakeTesla from '../assets/img/makes/tesla.jpg';
import MakeToyota from '../assets/img/makes/toyota.jpg';
import MakeVolkswagen from '../assets/img/makes/volkswagen.jpg';
import MakeVolvo from '../assets/img/makes/volvo.jpg';

let icons = [];
icons.DashboardIcon = DashboardIcon;
icons.ZakazkyIcon = ZakazkyIcon;
icons.ServisnaKnizkaIcon = ServisnaKnizkaIcon;
icons.UzivateliaIcon = UzivateliaIcon;
icons.ZakazniciIcon = ZakazniciIcon;
icons.VozidlaIcon = VozidlaIcon;
icons.KalendarIcon = KalendarIcon;
icons.UschovnaIcon = UschovnaIcon;
icons.PriestoryIcon = PriestoryIcon;
icons.DiagnostikaIcon = DiagnostikaIcon;
icons.InventarIcon = InventarIcon;
icons.NahradneDielyIcon = NahradneDielyIcon;
icons.AttendanceIcon = AttendanceIcon;
icons.WaitlistIcon = WaitlistIcon;
icons.PermissionsIcon = PermissionsIcon;
icons.ClaimsIcon = ClaimsIcon;
icons.EvrcIcon = EvrcIcon;
icons.DriveIcon = DriveIcon;
icons.AssetsIcon = AssetsIcon;
icons.PostIcon = PostIcon;
icons.ChatIcon = ChatIcon;
icons.EscapeRoomIcon = EscapeRoomIcon;
icons.IotDevicesIcon = IotDevicesIcon;
icons.IotValuesIcon = IotValuesIcon;
icons.SupportIcon = SupportIcon;
icons.ServicesIcon = ServicesIcon;
icons.AlbumsIcon = AlbumsIcon;
icons.ModulesIcon = ModulesIcon;

icons.Preview = DashboardIcon;
icons.List = ListIcon;
icons.New = NewIcon;
icons.Details = DescriptionIcon;
icons.History = HistoryIcon;
icons.Calendar = CalendarIcon;
icons.Analytics = PollIcon;
icons.Statistics = PieChartIcon;
icons.Settings = SettingsIcon;
icons.Help = HelpIcon;
icons.Save = SaveIcon;
icons.Delete = DeleteIcon;
icons.Edit = EditIcon;
icons.Reader = ReaderIcon;
icons.Download = DownloadIcon;
icons.AddExisting = AddExistingIcon;
icons.UnlinkExisting = UnlinkExistingIcon;

icons.RevenueIcon = RevenueIcon;
icons.Error403Icon = Error403Icon;
icons.RemoteHelpIcon = RemoteHelpIcon;
icons.PlayersIcon = PlayersIcon;

icons.en = FlagEn;
icons.sk = FlagSk;
icons.cs = FlagCs;
icons.de = FlagDe;
icons.ru = FlagRu;

icons.acura = MakeAcura;
icons.audi = MakeAudi;
icons['alfa romeo'] = MakeAlfaRomeo;
icons.astonmartin = MakeAstonMartin;
icons.bentley = MakeBentley;
icons.bmw = MakeBmw;
icons.bugatti = MakeBugatti;
icons.buick = MakeBuick;
icons.cadillac = MakeCadillac;
icons.chevrolet = MakeChevrolet;
icons.chrysler = MakeChrysler;
icons.citroën = MakeCitroen;
icons.daewoo = MakeDaewoo;
icons.dodge = MakeDodge;
icons.dacia = MakeDacia;
icons.ford = MakeFord;
icons.ferrari = MakeFerrari;
icons.fiat = MakeFiat;
icons.geely = MakeGeely;
icons.genesis = MakeGenesis;
icons.gm = MakeGm;
icons.gmc = MakeGmc;
icons.honda = MakeHonda;
icons.hyundai = MakeHyundai;
icons.infiniti = MakeInfiniti;
icons.iveco = MakeIveco;
icons.jaguar = MakeJaguar;
icons.jeep = MakeJeep;
icons.kia = MakeKia;
icons.koenigsegg = MakeKoenigsegg;
icons.lamborghini = MakeLamborghini;
icons.lada = MakeLada;
icons.landrover = MakeLandrover;
icons.lexus = MakeLexus;
icons.man = MakeMan;
icons.maserati = MakeMaserati;
icons.mazda = MakeMazda;
icons.mclaren = MakeMcLaren;
icons['mercedes-benz'] = MakeMercedesBenz;
icons.mini = MakeMini;
icons.mitsubishi = MakeMitsubishi;
icons.nissan = MakeNissan;
icons.opel = MakeOpel;
icons.other = MakeOther;
icons.pagani = MakePagani;
icons.peugeot = MakePeugeot;
icons.porsche = MakePorsche;
icons.ram = MakeRam;
icons.renault = MakeRenault;
icons.rollsroyce = MakeRollsRoyce;
icons.rover = MakeRover;
icons.saab = MakeSaab;
icons.seat = MakeSeat;
icons['škoda'] = MakeSkoda;
icons['ssangyong'] = MakeSsangYong;
icons.subaru = MakeSubaru;
icons.suzuki = MakeSuzuki;
icons.tata = MakeTata;
icons.tesla = MakeTesla;
icons.toyota = MakeToyota;
icons.volkswagen = MakeVolkswagen;
icons.vw = MakeVolkswagen;
icons.volvo = MakeVolvo;
export default icons;