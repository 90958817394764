import React, {useState, useEffect} from "react";
import Tmlaka from './Templates/tmlaka';
import TsensorChart from './Templates/tsensorChart';
import UnderConstructionComponent from '../../components/UnderConstruction/UnderConstruction';
import {publicService} from "../../services/public.service";

const Public = (props) => {
  const [template, setTemplate] = useState();

  const [groups, setGroups] = useState([]);

  const getUrlDetails = () => {
    const hash = props.match.params.hash;
    publicService.loadUrlDetails(hash)
    .then(() => {
        let url = JSON.parse(localStorage.getItem('public'));
        let template
        if ((url && url.TemplateId)) {
            switch (url.TemplateId) {
                case 1:
                  template = <Tmlaka/>;
                  break;
                case 2:
                  template = <TsensorChart/>;
                  break;
                default:
                  template = <UnderConstructionComponent />;
              }
        }
        setTemplate(template);
      });
  };

  useEffect(() => {
    getUrlDetails();
    }, []);

      if(template)
      {
          return template;
      }
      else
      {
          return '';
      }
}

export default Public;