import {servicesConstants} from '../constants/services.constants';

export function Services(state = {}, action) {
  switch (action.type) {
    case servicesConstants.ORDERS_SET_SUBMENU:
      return {
        ...state,
        ...state.services,
        submenu: action.submenu
      };

    default:
      return state
  }
}