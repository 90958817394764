import React, {useState, useEffect} from 'react';
import { iotGroupServicePublic } from '../../../services/public.iotGroup.service';
import IotGroupT1 from '../../../components/Public/IotGroupT1'
import '../../../assets/css/tmlaka.css';

const Tmlaka = (props) => {
  const [groups, setGroups] = useState([]);

  const getGroups = () => {
    const data = iotGroupServicePublic.loadGroups();
    setGroups(data);
  };

  useEffect(() => {
    getGroups();
    }, []);

    useEffect(() => {
        const timeWidget = document.createElement('script');
        timeWidget.src = "https://widget.time.is/en.js";
        timeWidget.async = true;
        timeWidget.onload = function() {
            window.time_is_widget.init({ Haligovce_z705: { template: "TIME<br/>SUN", sun_format: "Východ slnka: srhour:srminute Západ slnka: sshour:ssminute &nbsp; Dĺžka dňa: dlhoursh dlminutesm", coords: "49.3634800,20.4477600" } });
          };
        document.body.appendChild(timeWidget);

        const wheatherWidgetStyle = document.createElement('link');
        wheatherWidgetStyle.href = "https://s.aimg.sk/weather/css/widget/widget400x225.css?v=5";
        wheatherWidgetStyle.id = "widgetcss";
        wheatherWidgetStyle.rel = "stylesheet";
        wheatherWidgetStyle.media="all";
        wheatherWidgetStyle.type = "text/css";
        document.body.appendChild(wheatherWidgetStyle);

        const wheatherWidget = document.createElement('script');
        wheatherWidget.src = "https://pocasie.aktuality.sk/pocasie-na-web/weather/haligovce/?w=640&h=225&l=400x225&c=interaktivna&s=0&o=1";
        wheatherWidget.async = true;
        wheatherWidget.onload = function() {
            //document.getElementById('widgetweather').innerHTML = '<a href=\'https://pocasie.aktuality.sk/haligovce/\' title=\'Počasie\' id=\'widgetweatherweather\' class=\'widgetweatherweather\' style=\'display:block !important;\'>Počasie Haligovce</a>';
          };
        document.body.appendChild(wheatherWidget);
      return () => {
          document.body.removeChild(timeWidget);
          document.body.removeChild(wheatherWidget);
        }
      }, []);

  return (
      <div>
    <section className='weather'>
        <div id='widgetweather'>
            <div id='widgetweather'>
            <a href='https://pocasie.aktuality.sk/haligovce/' title='Počasie' id='widgetweatherweather' className='widgetweatherweather' style={{display:"block !important"}}>Počasie Haligovce</a>
            </div>
        </div>
    </section>
    <section className='temperatures'>
    {groups.map((group) => (
        <IotGroupT1 key={group} groupId={group}/>
    ))}
    </section>
    <section className='footer'>
        <br />
        <a href="https://time.is/Haligovce" id="time_is_link">Presný čas v Haligovciach:</a>
        <span id="Haligovce_z705"></span>
    </section>
    </div>
  );
};

export default Tmlaka;