import request from 'superagent';
import {API_URL} from '../constants';
import {authHeader} from '../helpers';

/**
 * Calls API to get Users
 */

export const publicService = {
  loadUrlDetails,
};

function loadUrlDetails(hash) {
  const url = `${API_URL}/public/urls/${hash}`;
  return request
    .get(url)
    .set('Accept', 'application/json')
    .then(url => {
      if ((url && url.body)) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('public', url.body);
      }
    })
    .catch(err => {
      console.log(err.status)
    });
}
