import React from "react";
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from "react-intl";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: "100%",
  },
  formLabel: {
    color: "red",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
});

function UnderConstruction(props) {
  const {classes} = props;
  return (
    <div>
      <Card>
        <CardHeader color="warning">
          <h4 className={classes.cardTitleWhite}>
            <FormattedMessage id={"Error.underConstruction"} defaultMessage="Under Construction"/></h4>
        </CardHeader>
        <CardBody>
          <Typography component="p">
            <FormattedMessage id={"Error.underConstructionLong"} defaultMessage="Under Construction"/>
          </Typography>
        </CardBody>
      </Card>
    </div>
  );
}

export default withStyles(styles)(UnderConstruction);
