/**
 * authHeader object for API calls that need authorization
 * @returns { *} Returns Authorization Object
 */
export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return {'Authorization': 'Bearer ' + user.token};
  } else {
    return {};
  }
}

export function authHeaderPublic() {
  // return authorization header with jwt token
  let url = JSON.parse(localStorage.getItem('public'));

  if (url && url.PublicToken) {
    return {'Authorization': 'Bearer ' + url.PublicToken};
  } else {
    return {};
  }
}
