import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import icons from "../../variables/icons";
import sidebarStyle from "../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import {FormattedMessage} from "react-intl";
import {LANG} from '../../constants';
import PerfectScrollbar from "perfect-scrollbar";
import {hasPermission, translate} from '../../functions';
import layout from '../../variables/layouts';

class Sidebar extends React.Component {
  // verifies if routeName is the one active (in browser input)
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const ps = new PerfectScrollbar(this.refs.sidebarWrapper);
  }

  activeRoute = (routeName) => (this.props.location.pathname.indexOf(routeName) > -1);

  render() {
    const {classes, color, logo, image, logoText, routes} = this.props;

    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) return null;
          var activePro = " ";
          var listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: this.activeRoute(prop.path)
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: this.activeRoute(prop.path[LANG])
          });

          return (
            <NavLink
              to={`${prop.path[LANG]}/zoznam`}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              {hasPermission(prop.leastPermission) ?
                <ListItem button className={classes.itemLink + listItemClasses}>
                  <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                    {React.createElement(icons[prop.icon])}
                  </ListItemIcon>
                  <ListItemText
                    primary={<FormattedMessage id={prop.translationId} defaultMessage="Menu item"/>}
                    className={classes.itemText + whiteFontClasses}
                    disableTypography={true}
                  />
                </ListItem> : null
              }
            </NavLink>
          );
        })}
      </List>
    );

    var brand = (
      <div className={classes.logo}>
        <a href={`/${LANG}`} className={classes.logoLink}>
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img}/>
          </div>
          <span className={classes.logoText} style={{color: layout.header.color}}> {logoText}</span>
        </a>
      </div>
    );

    const drawer = ({variant, anchor, props}) => (
      <Drawer
        variant={variant}
        anchor={anchor}
        open={props.open}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        {brand}
        <div className={classes.sidebarWrapper} ref="sidebarWrapper">{links}</div>
        {image !== undefined ? (
          <div
            className={classes.background}
            style={{backgroundImage: "url(" + image + ")"}}
          />
        ) : null}
      </Drawer>
    );

    return (
      <div>
        <Hidden xsDown implementation="css">
          {drawer({variant: 'permanent', anchor: 'left', props: this.props})}
        </Hidden>
        <Hidden xsUp implementation="css">
          {drawer({variant: 'temporary', anchor: 'left', props: this.props})}
        </Hidden>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
