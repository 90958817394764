export const customersConstants = {
  CUSTOMERS_SET_SUBMENU: 'CUSTOMERS_SET_SUBMENU',

  CUSTOMERS_LOAD_DATA_REQUEST: 'CUSTOMERS_LOAD_DATA_REQUEST',
  CUSTOMERS_LOAD_DATA_SUCCESS: 'CUSTOMERS_LOAD_DATA_SUCCESS',
  CUSTOMERS_LOAD_DATA_FAILURE: 'CUSTOMERS_LOAD_DATA_FAILURE',

  CUSTOMERS_LOAD_DETAIL_SUCCESS: 'CUSTOMERS_LOAD_DETAIL_SUCCESS',
  CUSTOMERS_LOAD_DETAIL_FAILURE: 'CUSTOMERS_LOAD_DETAIL_FAILURE',

  CUSTOMERS_SAVE_DATA_SUCCESS: 'CUSTOMERS_SAVE_DATA_SUCCESS',
  CUSTOMERS_SAVE_DATA_FAILURE: 'CUSTOMERS_SAVE_DATA_FAILURE',

  CUSTOMERS_RESET_DETAIL_SUCCESS: 'CUSTOMERS_RESET_DETAIL_SUCCESS',

  CUSTOMERS_SET_DETAIL_ID: 'CUSTOMERS_SET_DETAIL_ID',
};