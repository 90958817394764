export const iotDevicesConstants = {
  IOT_DEVICES_LOAD_DATA_REQUEST: 'IOT_DEVICES_LOAD_DATA_REQUEST',
  IOT_DEVICES_LOAD_DATA_SUCCESS: 'IOT_DEVICES_LOAD_DATA_SUCCESS',
  IOT_DEVICES_LOAD_DATA_FAILURE: 'IOT_DEVICES_LOAD_DATA_FAILURE',

  IOT_DEVICES_SAVE_DATA_SUCCESS: 'IOT_DEVICES_SAVE_DATA_SUCCESS',
  IOT_DEVICES_SAVE_DATA_FAILURE: 'IOT_DEVICES_SAVE_DATA_FAILURE',

  IOT_DEVICES_LOAD_DETAIL_SUCCESS: 'IOT_DEVICES_LOAD_DETAIL_SUCCESS',
  IOT_DEVICES_LOAD_DETAIL_FAILURE: 'IOT_DEVICES_LOAD_DETAIL_FAILURE',

  IOT_DEVICES_SET_DETAIL_ID: 'IOT_DEVICES_SET_DETAIL_ID',
  IOT_DEVICES_ADD_TO_SELECTED: 'IOT_DEVICES_ADD_TO_SELECTED',
};