import {combineReducers} from 'redux';

import {common} from './common.reducer';
import {Vehicles} from './vehicles.reducer';
import {Customers} from './customers.reducer';
import {Orders} from './orders.reducer';
import {EscapeRooms} from './escape.reducer';
import {Evrc} from './evrc.reducer';
import {admin} from './admin.reducer';
import {Inventory} from './inventory.reducer';
import {permissions} from './permissions.reducer';
import {Users} from './users.reducer';
import {history} from './history.reducer';
import {Post} from './post.reducer';
import {chat} from './chat.reducer';
import {IotDevices} from './iotDevices.reducer';
import {IotValues} from './iotValues.reducer';
import {Drive} from './drive.reducer';
import {Assets} from './assets.reducer';
import { Services } from './services.reducer';
import { Albums } from './albums.reducer';

const rootReducer = combineReducers({
  common,
  Vehicles,
  Customers,
  Orders,
  Evrc,
  EscapeRooms,
  admin,
  Inventory,
  permissions,
  Users,
  history,
  Post,
  chat,
  IotDevices,
  IotValues,
  Drive,
  Assets,
  Services,
  Albums,
});

export default rootReducer;
