import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Poppers from '@material-ui/core/Popper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import headerLinksStyle from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import icons from "../../variables/icons";
import {getUserInfo, hasPermission} from "../../functions";
import Button from "../../components/CustomButtons/Button.jsx";
import Company from '@material-ui/icons/Repeat';
import {connect} from "react-redux";
import {adminActions} from "../../actions/admin.actions";

class Profile extends React.Component {
  state = {
    open: false,
    openProfile: false,
    currentLang: '',
    selectedIndex: 1,
    anchorEl: {}
  };

  handleToggle = () => {
    this.setState(state => ({open: !state.open}));
  };

  handleToggleProfile = () => {
    this.setState(state => ({openProfile: !state.openProfile}));
  };

  handleClose = event => {

    this.setState({open: false});
  };

  handleCloseProfile = event => {
    this.setState({openProfile: false});
  };

  render() {
    const {classes, companies} = this.props;
    const {open, openProfile} = this.state;
    const user = getUserInfo();
    return (
      <div>

        {hasPermission(`CanReadModuleAdminCompanies`) ?
          <React.Fragment>
            <Button
              color="transparent"
              justIcon={true}
              buttonRef={node => {
                this.anchorEl = node;
              }}
              simple={true}
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              className={classes.buttonLink}
              onClick={this.handleToggle}
            >
              <Company className={classes.icons}/>
            </Button>
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        {
                          companies.map((item, key) => (
                            <MenuItem key={key} onClick={() => this.handleCompanyChange(item.id)}>{item.name}</MenuItem>
                          ))
                        }
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment> : ''
        }
        <Button
          color="transparent"
          justIcon={true}
          simple={true}
          aria-label="Profile"
          className={classes.buttonLink}
          onClick={this.handleToggleProfile}
        >
          {React.createElement(icons['ZakazniciIcon'])}
        </Button>
        <Poppers
          open={openProfile}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={
            classNames({[classes.popperClose]: !openProfile}) +
            " " +
            classes.pooperNav
          }
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseProfile}>
                  <MenuList>
                    <MenuItem key={'profil'} onClick={() => console.log('profil')}>{user.firstName} {user.lastName}<br/>
                      {user.company}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companies: state['admin'].companies,
});

const mapDispatchToProps = dispatch => {
  return {
    loadDataTable: () =>
      dispatch(adminActions.loadDataTable()),
    switchCompany: (companyId) =>
      dispatch(adminActions.switchCompany(companyId)),
  }
};

let ProfileConnect = connect(mapStateToProps, mapDispatchToProps)(Profile);
export default withStyles(headerLinksStyle)(ProfileConnect);
