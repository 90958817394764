import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Poppers from '@material-ui/core/Popper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import headerLinksStyle from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import icons from "../../variables/icons";
import {getLangFromUrl, hasPermission} from "../../functions";
import Button from "../../components/CustomButtons/Button.jsx";
import Company from '@material-ui/icons/Repeat';
import {connect} from "react-redux";
import {adminActions} from "../../actions/admin.actions";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openLang: false,
    currentLang: '',
    selectedIndex: 1,
    anchorEl: {}
  };

  componentDidMount() {
    //let user = JSON.parse(localStorage.getItem('user'));
    let lang = getLangFromUrl();
    this.setState({currentLang: lang});
    this.props.loadDataTable();
  }

  handleToggle = () => {
    this.setState(state => ({open: !state.open}));
  };

  handleToggleLang = () => {
    this.setState(state => ({openLang: !state.openLang}));
  };

  handleClose = event => {

    this.setState({open: false});
  };

  handleCloseLang = event => {
    this.setState({openLang: false});
  };

  handleCompanyChange = companyId => {
    this.setState({open: false});
    this.props.switchCompany(companyId);
  };

  /**
   * Handles language change from header menu
   * @param event
   * @param index
   * @param key
   */
  handleMenuItemClick = (event, index, key) => {
    this.setState({selectedIndex: index, anchorEl: null});
    let route = JSON.parse(localStorage.getItem('route'));
    window.location.href = route.path[key];
  };

  render() {
    const {classes, companies} = this.props;
    const {open, openLang} = this.state;

    const options = [
      'en',
      'sk',
      'cs',
      'de',
      'ru',
    ];

    return (
      <div>

        {hasPermission(`CanReadModuleAdminCompanies`) ?
          <React.Fragment>
            <Button
              color="transparent"
              justIcon={true}
              buttonRef={node => {
                this.anchorEl = node;
              }}
              simple={true}
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              className={classes.buttonLink}
              onClick={this.handleToggle}
            >
              <Company className={classes.icons}/>
            </Button>
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        {
                          companies.map((item, key) => (
                            <MenuItem key={key} onClick={() => this.handleCompanyChange(item.id)}>{item.name}</MenuItem>
                          ))
                        }
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment> : ''
        }
        <Button
          color="transparent"
          justIcon={true}
          simple={true}
          aria-label="Lang"
          className={classes.buttonLink}
          onClick={this.handleToggleLang}
        >
          <img src={icons[this.state.currentLang]} alt={this.state.currentLang} style={{width: '16px'}}
               className={classes.icons}/>
        </Button>
        <Poppers
          open={openLang}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={
            classNames({[classes.popperClose]: !openLang}) +
            " " +
            classes.pooperNav
          }
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseLang}>
                  <MenuList role="menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        //disabled={index === 0}
                        selected={option === this.state.currentLang}
                        onClick={event => this.handleMenuItemClick(event, index, option)}
                        className={classes.dropdownItem}
                      >
                        <img src={icons[option]} alt={{option}} style={{width: '16px'}}
                             className={classes.icons}/> {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companies: state['admin'].companies,
});

const mapDispatchToProps = dispatch => {
  return {
    loadDataTable: () =>
      dispatch(adminActions.loadDataTable()),
    switchCompany: (companyId) =>
      dispatch(adminActions.switchCompany(companyId)),
  }
};

let HeaderLinksConnect = connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
export default withStyles(headerLinksStyle)(HeaderLinksConnect);
