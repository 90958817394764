export const iotValuesConstants = {
  IOT_VALUES_LOAD_DATA_REQUEST: 'IOT_VALUES_LOAD_DATA_REQUEST',
  IOT_VALUES_LOAD_DATA_SUCCESS: 'IOT_VALUES_LOAD_DATA_SUCCESS',
  IOT_VALUES_LOAD_DATA_FAILURE: 'IOT_VALUES_LOAD_DATA_FAILURE',

  IOT_VALUES_SAVE_DATA_SUCCESS: 'IOT_VALUES_SAVE_DATA_SUCCESS',
  IOT_VALUES_SAVE_DATA_FAILURE: 'IOT_VALUES_SAVE_DATA_FAILURE',

  IOT_VALUES_LOAD_DETAIL_SUCCESS: 'IOT_VALUES_LOAD_DETAIL_SUCCESS',
  IOT_VALUES_LOAD_DETAIL_FAILURE: 'IOT_VALUES_LOAD_DETAIL_FAILURE',

  IOT_VALUES_LOAD_DETAIL_CHART_SUCCESS: 'IOT_VALUES_LOAD_DETAIL_CHART_SUCCESS',
  IOT_VALUES_LOAD_DETAIL_CHART_FAILURE: 'IOT_VALUES_LOAD_DETAIL_CHART_FAILURE',

  IOT_VALUES_SET_DETAIL_ID: 'IOT_VALUES_SET_DETAIL_ID',
};