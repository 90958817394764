import {driveConstants} from '../constants/drive.constants';

export function Drive(state = {}, action) {
  switch (action.type) {
    case driveConstants.DRIVE_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case driveConstants.DRIVE_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.Drive,
        data: action.data
      };
    case driveConstants.DRIVE_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.Drive,
        error: action.error
      };

    case driveConstants.DRIVE_SET_SUBMENU:
      return {
        ...state,
        ...state.Drive,
        submenu: action.submenu
      };
    default:
      return state
  }
}
