import React from "react";
import ReactDOM from "react-dom";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router-dom";
import {IntlProvider, addLocaleData} from 'react-intl';
import {createLogger} from 'redux-logger';
import {SnackbarProvider} from 'notistack';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from './reducers';
import reduxThunk from 'redux-thunk';
import "./assets/css/material-dashboard-react.css?v=1.5.0";
import indexRoutes from "./routes/index.jsx";
import en from 'react-intl/locale-data/en';
import sk from 'react-intl/locale-data/sk';
import cs from 'react-intl/locale-data/cs';
import de from 'react-intl/locale-data/de';
import ru from 'react-intl/locale-data/ru';
import {GET_ENVIRONMENT, LANG} from './constants';
import theme from 'variables/layouts';
import localeData from "./locales/data";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-156097427-1');
ReactGA.pageview(window.location.pathname + window.location.search);

addLocaleData([...en, ...sk, ...cs, ...de, ...ru]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

// Try full locale, try locale without region code, fallback to 'en'
const messages =
  localeData[LANG] ||
  localeData[language] ||
  localeData.en;

const hist = createBrowserHistory();

let middlewares = [reduxThunk];

// redux logger only for localhost debugging
if (GET_ENVIRONMENT === "localhost") {
  const logger = createLogger();
  middlewares.push(logger);
}

const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

const store = createStore(
  rootReducer,
  persistedState,
  compose(
    applyMiddleware(...middlewares),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <IntlProvider locale={language} messages={messages}>
        <SnackbarProvider maxSnack={3}>
          <Router history={hist}>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return <Route path={prop.path} component={prop.component} key={key}/>;
              })}
            </Switch>
          </Router>
        </SnackbarProvider>
      </IntlProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
