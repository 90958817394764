export const evrcConstants = {
  EVRC_SET_SUBMENU: 'EVRC_SET_SUBMENU',

  EVRC_LOAD_DATA_REQUEST: 'EVRC_LOAD_DATA_REQUEST',
  EVRC_LOAD_DATA_SUCCESS: 'EVRC_LOAD_DATA_SUCCESS',
  EVRC_LOAD_DATA_FAILURE: 'EVRC_LOAD_DATA_FAILURE',

  EVRC_LOAD_DETAIL_SUCCESS: 'EVRC_LOAD_DETAIL_SUCCESS',
  EVRC_LOAD_DETAIL_FAILURE: 'EVRC_LOAD_DETAIL_FAILURE',

  EVRC_SAVE_DATA_SUCCESS: 'EVRC_SAVE_DATA_SUCCESS',
  EVRC_SAVE_DATA_FAILURE: 'EVRC_SAVE_DATA_FAILURE',

  EVRC_RESET_DETAIL_SUCCESS: 'EVRC_RESET_DETAIL_SUCCESS',

  EVRC_SET_DETAIL_ID: 'EVRC_SET_DETAIL_ID',
  EVRC_ADD_TO_SELECTED: 'EVRC_ADD_TO_SELECTED',
};