import {layout as carsis} from './carsis.layout';
import {layout as personas} from './personas.layout';
import { layout as therooms } from './therooms.layout';
import { layout as webased } from './webased.layout';
import { layout as iot } from './iot.layout';

import {getProjectNameFromUrl} from "../../functions";

let project = getProjectNameFromUrl();
let layout = carsis;

switch (true) {
  case project === carsis.name:
    layout = carsis;
    break;
  case project === personas.name:
    layout = personas;
    break;
  case project === therooms.name:
    layout = therooms;
    break;
  case project === iot.name:
    layout = iot;
    break;
  case project === webased.name:
    layout = webased;
    break;
  default:
    layout = carsis;

}

export default layout;