export const ordersConstants = {
  ORDERS_SET_SUBMENU: 'ORDERS_SET_SUBMENU',

  ORDERS_LOAD_DATA_REQUEST: 'ORDERS_LOAD_DATA_REQUEST',
  ORDERS_LOAD_DATA_SUCCESS: 'ORDERS_LOAD_DATA_SUCCESS',
  ORDERS_LOAD_DATA_FAILURE: 'ORDERS_LOAD_DATA_FAILURE',

  ORDERS_LOAD_DETAIL_SUCCESS: 'ORDERS_LOAD_DETAIL_SUCCESS',
  ORDERS_LOAD_DETAIL_FAILURE: 'ORDERS_LOAD_DETAIL_FAILURE',

  ORDERS_SAVE_DATA_SUCCESS: 'ORDERS_SAVE_DATA_SUCCESS',
  ORDERS_SAVE_DATA_FAILURE: 'ORDERS_SAVE_DATA_FAILURE',

  ORDERS_RESET_DETAIL_SUCCESS: 'ORDERS_RESET_DETAIL_SUCCESS',

  ORDERS_SET_DETAIL_ID: 'ORDERS_SET_DETAIL_ID',
  ORDERS_ADD_TO_SELECTED: 'ORDERS_ADD_TO_SELECTED',
};