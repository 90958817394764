import Logo from '../../assets/img/iot-logo.png';
import Background from '../../assets/img/iot-cover.jpg';
import Cover from '../../assets/img/iot-cover.jpg';

export const layout = {
  name: 'iot',
  colors: {
    primaryColor: "#00BCD4",
    secondaryColor: "#303b43",
    warningColor: "#ff9800",
    dangerColor: "#f44336",
    successColor: "#4caf50",
    infoColor: "#2196F3",
    greyColor: "#22303A",
  },
  header: {
    projectName: 'IOT',
    logo: Logo,
    favicon: Logo,
    color: 'darkorange',
    backgroundColor: 'darkorange',
  },
  sidebar: {
    background: Background,
  },
  mainPanel: {
    paper: {
      backgroundColor: 'rgba(255,255,255,1)',
      color: 'rgba(0,0,0,.8)',
    },
    submenu: {},
    context: {
      backgroundColor: '',
    }
  },
  cover: {
    background: Cover
  }
};
