export const permissionsConstants = {
  PERMISSIONS_GETALL_REQUEST: 'PERMISSIONS_GETALL_REQUEST',
  PERMISSIONS_GETALL_SUCCESS: 'PERMISSIONS_GETALL_SUCCESS',
  PERMISSIONS_GETALL_FAILURE: 'PERMISSIONS_GETALL_FAILURE',

  PERMISSIONS_GET_PERMISSIONS_REQUEST: 'PERMISSIONS_GET_PERMISSIONS_REQUEST',
  PERMISSIONS_GET_PERMISSIONS_SUCCESS: 'PERMISSIONS_GET_PERMISSIONS_SUCCESS',
  PERMISSIONS_GET_PERMISSIONS_FAILURE: 'PERMISSIONS_GET_PERMISSIONS_FAILURE',

  PERMISSIONS_GET_ROLE_PERMISSIONS_REQUEST: 'PERMISSIONS_GET_ROLE_PERMISSIONS_REQUEST',
  PERMISSIONS_GET_ROLE_PERMISSIONS_SUCCESS: 'PERMISSIONS_GET_ROLE_PERMISSIONS_SUCCESS',
  PERMISSIONS_GET_ROLE_PERMISSIONS_FAILURE: 'PERMISSIONS_GET_ROLE_PERMISSIONS_FAILURE',

  PERMISSIONS_GET_USER_ROLES_REQUEST: 'PERMISSIONS_GET_USER_ROLES_REQUEST',
  PERMISSIONS_GET_USER_ROLES_SUCCESS: 'PERMISSIONS_GET_USER_ROLES_SUCCESS',
  PERMISSIONS_GET_USER_ROLES_FAILURE: 'PERMISSIONS_GET_USER_ROLES_FAILURE',

  PERMISSIONS_GET_COMPANIES_REQUEST: 'PERMISSIONS_GET_COMPANIES_REQUEST',
  PERMISSIONS_GET_COMPANIES_SUCCESS: 'PERMISSIONS_GET_COMPANIES_SUCCESS',
  PERMISSIONS_GET_COMPANIES_FAILURE: 'PERMISSIONS_GET_COMPANIES_FAILURE',

  PERMISSIONS_GET_MODULES_REQUEST: 'PERMISSIONS_GET_MODULES_REQUEST',
  PERMISSIONS_GET_MODULES_SUCCESS: 'PERMISSIONS_GET_MODULES_SUCCESS',
  PERMISSIONS_GET_MODULES_FAILURE: 'PERMISSIONS_GET_MODULES_FAILURE',

  PERMISSIONS_GET_COMPANY_MODULES_REQUEST: 'PERMISSIONS_GET_COMPANY_MODULES_REQUEST',
  PERMISSIONS_GET_COMPANY_MODULES_SUCCESS: 'PERMISSIONS_GET_COMPANY_MODULES_SUCCESS',
  PERMISSIONS_GET_COMPANY_MODULES_FAILURE: 'PERMISSIONS_GET_COMPANY_MODULES_FAILURE',

  PERMISSIONS_SET_ROLE: 'PERMISSIONS_SET_ROLE',
  PERMISSIONS_SET_MODULE: 'PERMISSIONS_SET_MODULE',
  PERMISSIONS_SET_USER: 'PERMISSIONS_SET_USER',
  PERMISSIONS_SET_COMPANY: 'PERMISSIONS_SET_COMPANY',
};