import {permissionsConstants} from '../constants/permissions.constants';

export function permissions(state = {
  roles: [],
  permissions: [],
  rolePermissions: [],
  userRoles: [],
  companies: [],
  modules: [],
  companyModules: [],
  error: {},
  isSubmitting: false,
}, action) {
  switch (action.type) {
    case permissionsConstants.PERMISSIONS_GETALL_REQUEST:
      return {
        loading: true
      };
    case permissionsConstants.PERMISSIONS_GETALL_SUCCESS:
      return {
        ...state,
        ...state.permissions,
        roles: action.roles
      };
    case permissionsConstants.PERMISSIONS_GETALL_FAILURE:
      return {
        ...state,
        ...state.permissions,
        error: action.error
      };

    case permissionsConstants.PERMISSIONS_GET_PERMISSIONS_REQUEST:
    case permissionsConstants.PERMISSIONS_GET_ROLE_PERMISSIONS_REQUEST:
    case permissionsConstants.PERMISSIONS_GET_USER_ROLES_REQUEST:
    case permissionsConstants.PERMISSIONS_GET_COMPANIES_REQUEST:
    case permissionsConstants.PERMISSIONS_GET_COMPANY_MODULES_REQUEST:
    case permissionsConstants.PERMISSIONS_GET_MODULES_REQUEST:
      return {
        loading: true
      };
    case permissionsConstants.PERMISSIONS_GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        ...state.permissions,
        permissions: action.permissions
      };
    case permissionsConstants.PERMISSIONS_GET_ROLE_PERMISSIONS_FAILURE:
    case permissionsConstants.PERMISSIONS_GET_USER_ROLES_FAILURE:
    case permissionsConstants.PERMISSIONS_GET_PERMISSIONS_FAILURE:
    case permissionsConstants.PERMISSIONS_GET_COMPANIES_FAILURE:
    case permissionsConstants.PERMISSIONS_GET_COMPANY_MODULES_FAILURE:
    case permissionsConstants.PERMISSIONS_GET_MODULES_FAILURE:
      return {
        ...state,
        ...state.permissions,
        error: action.error
      };

    case permissionsConstants.PERMISSIONS_GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        ...state.permissions,
        rolePermissions: action.rolePermissions
      };

    case permissionsConstants.PERMISSIONS_GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        ...state.permissions,
        userRoles: action.userRoles
      };

    case permissionsConstants.PERMISSIONS_GET_COMPANIES_SUCCESS:
      return {
        ...state,
        ...state.permissions,
        companies: action.companies
      };

    case permissionsConstants.PERMISSIONS_GET_MODULES_SUCCESS:
      return {
        ...state,
        ...state.permissions,
        modules: action.modules
      };

    case permissionsConstants.PERMISSIONS_GET_COMPANY_MODULES_SUCCESS:
      return {
        ...state,
        ...state.permissions,
        companyModules: action.companyModules
      };

    case permissionsConstants.PERMISSIONS_SET_ROLE:
      return {
        ...state,
        ...state.permissions,
        selectedRoleId: action.selectedRoleId
      };

      case permissionsConstants.PERMISSIONS_SET_MODULE:
      return {
        ...state,
        ...state.permissions,
        selectedModule: action.selectedModule
      };

      case permissionsConstants.PERMISSIONS_SET_USER:
      return {
        ...state,
        ...state.permissions,
        selectedUserId: action.selectedUserId
      };

    case permissionsConstants.PERMISSIONS_SET_COMPANY:
      return {
        ...state,
        ...state.permissions,
        selectedCompanyId: action.selectedCompanyId
      };
    default:
      return state
  }
}