// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

let routes = [
  {
    "path": {
      "sk": "/sk/nastenka-kancelaria",
      "cs": "/cs/nastenka",
      "en": "/en/dashboard",
      "de": "/de/dashboard",
      "ru": "/ru/"
    },
    "translationId": "Common.dashboard",
    "icon": "DashboardIcon",
    "component": "DashboardPage",
    "defaultTab": "Common.list",
    "leastPermission": ""
  },
  {
    "path": {
      "sk": "/sk/zakazky",
      "cs": "/cs/zakazky",
      "en": "/en/orders",
      "de": "/de/bestellungen",
      "ru": "/ru/"
    },
    "translationId": "Common.orders",
    "icon": "ZakazkyIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleOrders"
  },
  {
    "path": {
      "sk": "/sk/evidencia-vozidiel",
      "cs": "/cs/evidence-vozu",
      "en": "/en/vehicles",
      "de": "/de/autos",
      "ru": "/ru/avtomobil"
    },
    "translationId": "Common.vehicles",
    "icon": "VozidlaIcon",
    "component": "VehiclesPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleVehicles"
  },
  {
    "path": {
      "sk": "/sk/zakaznici",
      "cs": "/cs/zakaznici",
      "en": "/en/customers",
      "de": "/de/kunden",
      "ru": "/ru/"
    },
    "translationId": "Common.customers",
    "icon": "ZakazniciIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleCustomers"
  },
  {
    "path": {
      "sk": "/sk/kalendar",
      "cs": "/cs/kalendar",
      "en": "/en/calendar",
      "de": "/de/kalender",
      "ru": "/ru/"
    },
    "translationId": "Common.calendar",
    "icon": "KalendarIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleCalendar"
  },
  {
    "path": {
      "sk": "/sk/uzivatelia",
      "cs": "/cs/uzivatele",
      "en": "/en/users",
      "de": "/de/benutzer",
      "ru": "/ru/пользователи"
    },
    "translationId": "Common.users",
    "icon": "UzivateliaIcon",
    "component": "UserProfile",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleUsers"
  },
  {
    "path": {
      "sk": "/sk/dochadzka",
      "cs": "/cs/dochazka",
      "en": "/en/attendance",
      "de": "/de/anwesenheit",
      "ru": "/ru/"
    },
    "translationId": "Common.attendance",
    "icon": "AttendanceIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleAttendance"
  },
  {
    "path": {
      "sk": "/sk/servisna-knizka",
      "cs": "/cs/servisni-knizka",
      "en": "/en/service-book",
      "de": "/de/gesangbuch",
      "ru": "/ru/"
    },
    "translationId": "Common.serviceBook",
    "icon": "ServisnaKnizkaIcon",
    "component": "UnderConstructionPageServiceBook",
    "defaultTab": "Common.list",
    "leastPermission": ""
  },
  {
    "path": {
      "sk": "/sk/uschovna",
      "cs": "/cs/uschovna",
      "en": "/en/tire-storage",
      "de": "/de/reifen-lagerung",
      "ru": "/ru/"
    },
    "translationId": "Common.tireStorage",
    "icon": "UschovnaIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleTireStorage"
  },
  {
    "path": {
      "sk": "/sk/nahradne-diely",
      "cs": "/cs/nahradni-dily",
      "en": "/en/replacement-parts",
      "de": "/de/ersatzteile",
      "ru": "/ru/"
    },
    "translationId": "Common.replacementParts",
    "icon": "NahradneDielyIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleReplacementParts"
  },
  {
    "path": {
      "sk": "/sk/inventar",
      "cs": "/cs/inventar",
      "en": "/en/inventory",
      "de": "/de/inventar",
      "ru": "/ru/инвентарь"
    },
    "translationId": "Common.inventory",
    "icon": "InventarIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleInventory"
  },
  {
    "path": {
      "sk": "/sk/priestory",
      "cs": "/cs/prostory",
      "en": "/en/rooms",
      "de": "/de/raume",
      "ru": "/ru/"
    },
    "translationId": "Common.rooms",
    "icon": "PriestoryIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleRooms"
  },
  {
    "path": {
      "sk": "/sk/diagnostika",
      "cs": "/cs/diagnostika",
      "en": "/en/diagnostics",
      "de": "/de/fehlerdiagnose",
      "ru": "/ru/"
    },
    "translationId": "Common.diagnostics",
    "icon": "DiagnostikaIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleDiagnostics"
  },
  {
    "path": {
      "sk": "/sk/poradovnik",
      "cs": "/cs/poradovnik",
      "en": "/en/waitlist",
      "de": "/de/warteliste",
      "ru": "/ru/"
    },
    "translationId": "Common.waitlist",
    "icon": "WaitlistIcon",
    "component": "UnderConstructionPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleWaitlist"
  },
    {
      "path": {
        "sk": "/sk/opravnenia",
        "cs": "/cs/opravneni",
        "en": "/en/permissions",
        "de": "/de/berechtigungen",
        "ru": "/ru/разрешений"
      },
      "translationId": "Common.permissions",
      "icon": "PermissionsIcon",
      "component": "PermissionsPage",
      "defaultTab": "Permissions.roles",
      "leastPermission": "CanReadModulePermissions"
    },
    {
      "path": {
        "sk": "/sk/reklamacie",
        "cs": "/cs/reklamace",
        "en": "/en/claims",
        "de": "/de/beschwerde",
        "ru": "/ru/жалоба"
      },
      "translationId": "Common.claims",
      "icon": "ClaimsIcon",
      "component": "ClaimsPage",
      "defaultTab": "Common.list",
      "leastPermission": "CanReadModuleClaims"
    },
  {
    "path": {
      "sk": "/sk/evrc",
      "cs": "/cs/evrc",
      "en": "/en/evrc",
      "de": "/de/evrc",
      "ru": "/ru/еврц"
    },
    "translationId": "Common.evrc",
    "icon": "EvrcIcon",
    "component": "EvrcPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleEvrc"
  },
  {
    "path": {
      "sk": "/sk/posta",
      "cs": "/cs/posta",
      "en": "/en/post",
      "de": "/de/post",
      "ru": "/ru/почта"
    },
    "translationId": "Common.post",
    "icon": "PostIcon",
    "component": "PostPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModulePost"
  },
  {
    "path": {
      "sk": "/sk/chat",
      "cs": "/cs/chat",
      "en": "/en/chat",
      "de": "/de/chat",
      "ru": "/ru/chat"
    },
    "translationId": "Common.chat",
    "icon": "ChatIcon",
    "component": "ChatPage",
    "defaultTab": "Common.chat",
    "leastPermission": "CanReadModuleChat"
  },
  {
    "path": {
      "sk": "/sk/escape",
      "cs": "/cs/escape",
      "en": "/en/escape",
      "de": "/de/escape",
      "ru": "/ru/escape"
    },
    "translationId": "Common.escapeRooms",
    "icon": "EscapeRoomIcon",
    "component": "EscapeRoomsPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleEscapeRooms"
  },
  {
    "path": {
      "sk": "/sk/iot-devices",
      "cs": "/cs/iot-devices",
      "en": "/en/iot-devices",
      "de": "/de/iot-devices",
      "ru": "/ru/iot-devices"
    },
    "translationId": "Common.iotDevices",
    "icon": "IotDevicesIcon",
    "component": "IotDevicesPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleIotDevices"
  },
  {
    "path": {
      "sk": "/sk/iot-values",
      "cs": "/cs/iot-values",
      "en": "/en/iot-values",
      "de": "/de/iot-values",
      "ru": "/ru/iot-values"
    },
    "translationId": "Common.iotValues",
    "icon": "IotValuesIcon",
    "component": "IotValuesPage",
    "defaultTab": "Common.list",
    "leastPermission": "CanReadModuleIotValues"
  },
    {
      "path": {
        "sk": "/sk/pomocnik",
        "cs": "/cs/support",
        "en": "/en/support",
        "de": "/de/support",
        "ru": "/ru/support"
      },
      "translationId": "Common.support",
      "icon": "SupportIcon",
      "component": "SupportPage",
      "defaultTab": "Common.list",
      "leastPermission": "CanReadModuleSupport"
    },
    {
      "path": {
        "sk": "/sk/drive",
        "cs": "/cs/drive",
        "en": "/en/drive",
        "de": "/de/drive",
        "ru": "/ru/drive"
      },
      "translationId": "Common.drive",
      "icon": "DriveIcon",
      "component": "DrivePage",
      "defaultTab": "Common.list",
      "leastPermission": "CanReadModuleDrive"
    },
    {
      "path": {
        "sk": "/sk/tovar",
        "cs": "/cs/tovar",
        "en": "/en/assets",
        "de": "/de/assets",
        "ru": "/ru/assets"
      },
      "translationId": "Common.assets",
      "icon": "AssetsIcon",
      "component": "AssetsPage",
      "defaultTab": "Common.list",
      "leastPermission": "CanReadModuleAssets"
    },
    {
      "path": {
        "sk": "/sk/sluzby",
        "cs": "/cs/sluzby",
        'en': '/en/services',
        'de': '/de/services',
        'ru': '/ru/services'
      },
      'translationId': 'Common.services',
      'icon': 'ServicesIcon',
      'component': 'ServicesPage',
      'defaultTab': 'Common.list',
      'leastPermission': 'CanReadModuleServices'
    },
    {
      'path': {
        'sk': '/sk/albumy',
        'cs': '/cs/albumy',
        'en': '/en/albums',
        'de': '/de/albums',
        'ru': '/ru/albums'
      },
      'translationId': 'Common.albums',
      'icon': 'AlbumsIcon',
      'component': 'AlbumsPage',
      'defaultTab': 'Common.list',
      'leastPermission': 'CanReadModuleAlbums'
    },
  ]
;

var bugs = [
  'KE822KO - Motor oil exchange',
  'SB822AV - Front break exchange',
  'SB321BA - Turbo repair',
  'PO221CX - Pneu exchange'
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server,
  routes
};
