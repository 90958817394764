import React, {useState, useEffect} from 'react';
import { iotGroupServicePublic } from '../../services/public.iotGroup.service';

const IotGroupT1 = (props) => {
  const [group, setGroup] = useState([]);

  const getGroup = () => {
    iotGroupServicePublic.loadDetail(props.groupId)
      .then(response => {
        setGroup(response.data)
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getGroup();
    }, []);

    if (group && group.sensors)
    {
  return (
    <div className="sensor">
      <a href={`sensorchart/${group.id}`} className="fillthediv"><span></span></a>
      <h2>{group.name} - {group.description}</h2>
      <p>Namerané: {group.sensors[0].lastDate}</p>
      <h2>{group.sensors[0].lastValue} &#8451;</h2>
      MIN: <b>{group.sensors[0].minValue} &#8451;</b> - {group.sensors[0].minDate}<br />
      MAX: <b>{group.sensors[0].maxValue} &#8451;</b> - {group.sensors[0].maxDate}
      { group.sensors[1] ? 
        <p>Stav baterky: {group.sensors[1].lastValue} V</p>
        : ''}
    </div>
  );
        }
        else
        {
            return '';
        }
};

export default IotGroupT1;