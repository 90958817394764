import {usersConstants} from '../constants/users.constants';

export function Users(state = {}, action) {
  switch (action.type) {
    case usersConstants.USERS_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case usersConstants.USERS_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.Users,
        data: action.data
      };
    case usersConstants.USERS_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.Users,
        error: action.error
      };


    case usersConstants.USERS_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.Users,
        detail: action.detail
      };
    case usersConstants.USERS_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case usersConstants.USERS_SET_DETAIL_ID:
      return {
        ...state,
        ...state.Users,
        detailId: action.detailId
      };

    case usersConstants.USERS_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.Users,
        isSubmitting: action.isSubmitting
      };
    default:
      return state
  }
}