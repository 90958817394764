import request from 'superagent';
import {API_URL} from '../constants';
import {authHeader} from '../helpers';
import {getRouteInfo} from '../functions';

/**
 * Calls API to login
 *
 * @param email User's login email
 * @param password User's login password
 */

export let login = (email, password) => {
  const url = `${API_URL}/users/login`;
  return new Promise((resolve, reject) => {
    request
      .post(url)
      .set('Accept', 'application/json')
      .send({email, password})
      .then(user => {
        if ((user && user.body.token) || (user && user.body.accessToken)) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('user', user.text);
          window.location.href = user.body.landingRoute ? getRouteInfo(user.body.landingRoute, user.body.defaultLang) : '/sk/nastenka-kancelaria';
          resolve(200);
          console.log(user)
        }
      })
      .catch(err => {
        reject(err.status);
      });
  });
};

/**
 * Logging out user
 */
export let logout = () => {

  const url = `${API_URL}/users/logout`;
  request
    .get(url)
    .set('Accept', 'application/json')
    .set(authHeader())
    .then(() => {
      localStorage.removeItem('user');
      window.location.href = "/";
    })
    .catch(err => {
      console.log(err.response)
      if (err.response && err.response.statusCode === 401) {
        localStorage.removeItem('user');
        window.location.href = "/";
      }
    });
};
