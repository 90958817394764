import DashboardLayout from '../layouts/Dashboard/Dashboard';
import DomainLayout from '../layouts/Domain/Domain';
import PublicLayout from '../layouts/Public/Public';
import Public from "../views/Public/public.jsx";

const indexRoutes = [
  {path: '/sk/prihlasenie', component: DomainLayout},
  {path: '/cs/prihlaseni', component: DomainLayout},
  {path: '/:lang(en|sk|cs|de|ru)/p/:hash', component: Public},
  {path: '/:lang(en|sk|cs|de|ru)', component: DashboardLayout},
  {path: '/', component: DomainLayout}
];

export default indexRoutes;
