import {assetsConstants} from '../constants/assets.constants';

export function Assets(state = {}, action) {
  switch (action.type) {
    case assetsConstants.ASSETS_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case assetsConstants.ASSETS_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.assets,
        data: action.data
      };
    case assetsConstants.ASSETS_LOAD_DATA_FAILURE:
      return {
        error: action.error
      };

    case assetsConstants.ASSETS_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.assets,
        detail: action.detail
      };
    case assetsConstants.ASSETS_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case assetsConstants.ASSETS_RESET_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.assets,
        detail: action.detail
      };

    case assetsConstants.ASSETS_ADD_TO_SELECTED:
      let count = state.selected && state.selected.length;
      //state && state.selected && action.item ? state.selected.filter(item => item.id === action.item.id) : [];
      if (count === 0) {
        return {
          ...state,
          ...state.assets,
          selected: state && state.selected ? [...state.selected, action.item] : [action.item]
        };
      } else {
        return {
          ...state,
          ...state.assets,
          ...state.selected
        };
      }

    case assetsConstants.ASSETS_SET_SUBMENU:
      return {
        ...state,
        ...state.assets,
        submenu: action.submenu
      };

    case assetsConstants.ASSETS_SET_DETAIL_ID:
      return {
        ...state,
        ...state.assets,
        detailId: action.detailId
      };

    case assetsConstants.ASSETS_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.assets,
        isSubmitting: action.isSubmitting
      };
    default:
      return state
  }
}