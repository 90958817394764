export const inventoryConstants = {
  INVENTORY_LOAD_DATA_REQUEST: 'INVENTORY_LOAD_DATA_REQUEST',
  INVENTORY_LOAD_DATA_SUCCESS: 'INVENTORY_LOAD_DATA_SUCCESS',
  INVENTORY_LOAD_DATA_FAILURE: 'INVENTORY_LOAD_DATA_FAILURE',

  INVENTORY_SAVE_DATA_SUCCESS: 'INVENTORY_SAVE_DATA_SUCCESS',
  INVENTORY_SAVE_DATA_FAILURE: 'INVENTORY_SAVE_DATA_FAILURE',

  INVENTORY_LOAD_DETAIL_SUCCESS: 'INVENTORY_LOAD_DETAIL_SUCCESS',
  INVENTORY_LOAD_DETAIL_FAILURE: 'INVENTORY_LOAD_DETAIL_FAILURE',

  INVENTORY_LOAD_DATA_ITEMS_REQUEST: 'INVENTORY_LOAD_DATA_ITEMS_REQUEST',
  INVENTORY_LOAD_DATA_ITEMS_SUCCESS: 'INVENTORY_LOAD_DATA_ITEMS_SUCCESS',
  INVENTORY_LOAD_DATA_ITEMS_FAILURE: 'INVENTORY_LOAD_DATA_ITEMS_FAILURE',

  INVENTORY_SET_DETAIL_ID: 'INVENTORY_SET_DETAIL_ID',
  INVENTORY_RESET_DETAIL_SUCCESS: 'INVENTORY_RESET_DETAIL_SUCCESS',

  INVENTORY_SAVE_DATA_ITEM_SUCCESS: 'INVENTORY_SAVE_DATA_ITEM_SUCCESS',
  INVENTORY_SAVE_DATA_ITEM_FAILURE: 'INVENTORY_SAVE_DATA_ITEM_FAILURE',

};
