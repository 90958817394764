export const escapeConstants = {
  ESCAPE_LOAD_DATA_REQUEST: 'ESCAPE_LOAD_DATA_REQUEST',
  ESCAPE_LOAD_DATA_SUCCESS: 'ESCAPE_LOAD_DATA_SUCCESS',
  ESCAPE_LOAD_DATA_FAILURE: 'ESCAPE_LOAD_DATA_FAILURE',

  ESCAPE_SAVE_DATA_SUCCESS: 'ESCAPE_SAVE_DATA_SUCCESS',
  ESCAPE_SAVE_DATA_FAILURE: 'ESCAPE_SAVE_DATA_FAILURE',

  ESCAPE_LOAD_DETAIL_SUCCESS: 'ESCAPE_LOAD_DETAIL_SUCCESS',
  ESCAPE_LOAD_DETAIL_FAILURE: 'ESCAPE_LOAD_DETAIL_FAILURE',

  ESCAPE_LOAD_SELECTED_SUCCESS: 'ESCAPE_LOAD_SELECTED_SUCCESS',
  ESCAPE_ADD_TO_SELECTED: 'ESCAPE_ADD_TO_SELECTED',

  ESCAPE_SET_DETAIL_ID: 'ESCAPE_SET_DETAIL_ID',
};