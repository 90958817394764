import Logo from "../../assets/img/cs-retro.jpg";
import Background from '../../assets/img/carsis-wheel.jpg';
import Cover from '../../assets/img/carsis-car-1.jpg';
import red from "@material-ui/core/colors/red";
import indigo from "@material-ui/core/colors/indigo";

export const layout = {
  name: 'carsis',
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: indigo,
    secondary: red,
  },
  colors: {
    primaryColor: "#D12F2E",
    secondaryColor: "#303b43",
    warningColor: "#ff9800",
    dangerColor: "#f44336",
    successColor: "#4caf50",
    infoColor: "#2196F3",
    infoColorShadow: 'rgba(33,150,243, 0.28)',
    greyColor: "#22303A",
  },
  header: {
    projectName: 'Carsis',
    logo: Logo,
    favicon: Logo,
    color: 'white',
    background: Background,
  },
  sidebar: {
    background: Background,
    overlayColor: '#3C4A54',
    borderLeft: 'none',
  },
  mainPanel: {
    paper: {
      backgroundColor: 'rgba(255,255,255,1)',
      color: 'rgba(0,0,0,.8)',
    },
    submenu: {},
    context: {
      backgroundColor: '#f2f2f2',
    }
  },
  cover: {
    background: Cover
  }
};