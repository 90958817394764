/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import domainStyle from '../../assets/jss/material-dashboard-react/layouts/domainStyle.jsx';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { getProjectNameFromUrl } from '../../functions';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import * as userService from '../../services/user.service';
import layout from '../../variables/layouts';
import { layout as carsis } from '../../variables/layouts/carsis.layout';
import { layout as personas } from '../../variables/layouts/personas.layout';
import { layout as therooms } from '../../variables/layouts/therooms.layout';
import { layout as iot } from '../../variables/layouts/iot.layout';
import { layout as webased } from '../../variables/layouts/webased.layout';

function Alert(props) {
  const {classes, className, message, onClose, variant, ...other} = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      {...other}
    />
  );
}

Alert.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const styles1 = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const AlertWrapper = withStyles(styles1)(Alert);


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      submitted: false,
      mobileOpen: false,
      loading: false,
      error: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState({mobileOpen: !this.state.mobileOpen});
  };

  componentDidMount() {

    let project = getProjectNameFromUrl();
    let title = '';
    let favicon = '';

    switch (true) {
      case project === carsis.name:
        title = carsis.name;
        favicon = carsis.header.favicon;
        break;
      case project === personas.name:
        title = personas.name;
        favicon = personas.header.favicon;
        break;
      case project === therooms.name:
        title = therooms.name;
        favicon = therooms.header.favicon;
        break;
      case project === iot.name:
        title = iot.name;
        favicon = iot.header.favicon;
        break;
      case project === webased.name:
        title = webased.name;
        favicon = webased.header.favicon;
        break;
      default:
        title = 'webased';

    }

    document.title = title;

    let link = document.querySelector('link[rel="shortcut icon"]') ||
      document.querySelector('link[rel="icon"]');

    if (!link) {
      link = document.createElement('link');
      link.id = 'favicon';
      link.rel = 'shortcut icon';
      document.head.appendChild(link);
    }

    link.href = favicon;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({mobileOpen: false});
      }
    }
  }

  handleChange = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({submitted: true});
    const {email, password} = this.state;
    // const { dispatch } = this.props;
    if (email && password) {
      this.setState({loading: true});
      //dispatch(userActions.login(username, password));
      let login = userService.login(email, password);
      console.log(login);
      login.then(this.setState({error: false, loading: true, submitted: true}));
      login.catch((e) => {
        switch (e) {
          case 400:
            this.setState({error: e, loading: false, submitted: true});
            break;
          case 200:
            this.setState({error: false, loading: false, submitted: true});
            break;
          default:
            console.log('iný status kód...');
        }
      });
    }
  };

  render() {
    const {classes, ...rest} = this.props;
    let {loading, submitted, error} = this.state;

    return (
      <div className={`${classes.background}`}
           style={{
             backgroundImage: `url(${layout.cover && layout.cover.background ? layout.cover.background : ''})`,
             backgroundSize: 'cover',
             backgroundColor: `${layout.cover && layout.cover.backgroundColor ? layout.cover.backgroundColor : ''}`,
           }}>
        <main className={classes.main}>
          <CssBaseline/>
          <Paper elevation={2} className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Prihlásenie
            </Typography>
            <Typography component="h5" variant="h5">
              {layout.name}
            </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              {submitted && error === 400 ? <AlertWrapper
                variant="error"
                className={classes.margin}
                message="Nesprávne prihlasovacie údaje"
              /> : ''}
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  autoComplete="email"
                  autoFocus
                  disabled={loading}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Heslo</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  onChange={this.handleChange}
                  autoComplete="current-password"
                  disabled={loading}
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary"/>}
                label="Zapamätaj si ma"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loading}
              >
                Prihlásiť
                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
              </Button>
            </form>
          </Paper>
        </main>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(domainStyle)(App);
