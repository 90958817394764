import {commonConstants} from '../constants/common.constants';

export function common(state = {}, action) {
  switch (action.type) {
    case commonConstants.COMMON_SET_MODULE:
      return {
        ...state,
        module: action.module
      };
    case commonConstants.COMMON_SET_SUBMENU:
      return {
        ...state,
        submenu: action.submenu
      };
    case commonConstants.COMMON_SET_ACCORDION:
      return {
        ...state,
        accordion: action.accordion
      };
    default:
      return state
  }
}