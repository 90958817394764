import {adminConstants} from '../constants/admin.constants';

export function admin(state = {}, action) {
  switch (action.type) {
    case adminConstants.ADMIN_LOAD_COMPANY_DATA_REQUEST:
      return {
        loading: true
      };
    case adminConstants.ADMIN_LOAD_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        ...state.admin,
        companies: action.companies
      };
    case adminConstants.ADMIN_LOAD_COMPANY_DATA_FAILURE:
      return {
        error: action.error
      };

    case adminConstants.ADMIN_SWITCH_COMPANY_SUCCESS:
      return {
        ...state,
        ...state.admin,
        isSwitching: action.isSwitching
      };
    default:
      return state
  }
}