export const GET_ENVIRONMENT = !window.hasOwnProperty('JsApiConfig')
  ? 'localhost'
  : `${window.JsApiConfig.ApiBaseUrl}`;

console.log(GET_ENVIRONMENT);

//const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
let pathArray = window.location.pathname.split('/');
export const LANG = pathArray[1];

export const API_URL = GET_ENVIRONMENT === 'localhost' ? 'https://www.carsis.sk/api' : 'https://www.carsis.sk/api';

export const PHP_API_URL = GET_ENVIRONMENT === 'localhost' ? 'https://www.carsis.sk/phpapi' : 'https://www.carsis.sk/phpapi';
