import {adminConstants} from '../constants/admin.constants';
import {adminService} from "../services/admin.service";

export const adminActions = {
  loadDataTable,
  switchCompany,
};

function loadDataTable() {
  return dispatch => adminService.loadDataTable()
    .then((result) => {
      dispatch({
        type: adminConstants.ADMIN_LOAD_COMPANY_DATA_SUCCESS, companies: result.body
      });
    })
    .catch((error) => {
      dispatch({type: adminConstants.ADMIN_LOAD_COMPANY_DATA_FAILURE, error});
    });
}


function switchCompany(companyId) {
  return dispatch => adminService.switchCompany(companyId)
    .then((result) => {
      dispatch({
        type: adminConstants.ADMIN_SWITCH_COMPANY_SUCCESS, companies: result.body
      });
      window.location.reload(true);
    })
    .catch((error) => {
      dispatch({type: adminConstants.ADMIN_SWITCH_COMPANY_FAILURE, error});
    });
}
