import {inventoryConstants} from '../constants/inventory.constants';

export function Inventory(state = {}, action) {
  switch (action.type) {
    case inventoryConstants.INVENTORY_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case inventoryConstants.INVENTORY_LOAD_DATA_SUCCESS:
      return {
        data: action.data
      };
    case inventoryConstants.INVENTORY_LOAD_DATA_FAILURE:
      return {
        error: action.error
      };

    case inventoryConstants.INVENTORY_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.Inventory,
        detail: action.detail
      };
    case inventoryConstants.INVENTORY_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case inventoryConstants.INVENTORY_RESET_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.Inventory,
        detail: action.detail
      };

    case inventoryConstants.INVENTORY_LOAD_DATA_ITEMS_REQUEST:
      return {
        loading: true
      };
    case inventoryConstants.INVENTORY_LOAD_DATA_ITEMS_SUCCESS:
      return {
        ...state,
        ...state.Inventory,
        dataItems: action.dataItems
      };
    case inventoryConstants.INVENTORY_LOAD_DATA_ITEMS_FAILURE:
      return {
        error: action.error
      };

    case inventoryConstants.INVENTORY_SET_SUBMENU:
      return {
        ...state,
        ...state.Inventory,
        submenu: action.submenu
      };
    default:
      return state
  }
}
