export const usersConstants = {
  USERS_LOAD_DATA_REQUEST: 'USERS_LOAD_DATA_REQUEST',
  USERS_LOAD_DATA_SUCCESS: 'USERS_LOAD_DATA_SUCCESS',
  USERS_LOAD_DATA_FAILURE: 'USERS_LOAD_DATA_FAILURE',

  USERS_SAVE_DATA_SUCCESS: 'USERS_SAVE_DATA_SUCCESS',
  USERS_SAVE_DATA_FAILURE: 'USERS_SAVE_DATA_FAILURE',

  USERS_LOAD_DETAIL_SUCCESS: 'USERS_LOAD_DETAIL_SUCCESS',
  USERS_LOAD_DETAIL_FAILURE: 'USERS_LOAD_DETAIL_FAILURE',

  USERS_SET_DETAIL_ID: 'USERS_SET_DETAIL_ID',
};