import {chatConstants} from '../constants/chat.constants';

export function chat(state = {}, action) {
  switch (action.type) {
    case chatConstants.CHAT_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case chatConstants.CHAT_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.chat,
        data: action.data
      };
    case chatConstants.CHAT_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.chat,
        error: action.error
      };


    case chatConstants.CHAT_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.chat,
        detail: action.detail
      };
    case chatConstants.CHAT_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case chatConstants.CHAT_SET_DETAIL_ID:
      return {
        ...state,
        ...state.chat,
        detailId: action.detailId
      };

    case chatConstants.CHAT_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.chat,
        isSubmitting: action.isSubmitting
      };
    default:
      return state
  }
}