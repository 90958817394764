import request from 'superagent';
import {API_URL} from '../constants';
import { authHeaderPublic } from '../helpers';
import axios from 'axios';
import moment from "moment";

/**
 * Calls API to get Iot Devices
 */

export const iotValuesService = {
  loadDataTable,
  loadDetailChart,
  loadDetail,
  saveData,
};

function loadDataTable() {
  const startDate = moment().subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss');
  const endDate = moment().format('YYYY-MM-DDTHH:mm:ss');

  const url = `${API_URL}/iot/values?start=${startDate}&end=${endDate}&skip=0&take=5000&desc=true`;

  const options = {
    method: 'get',
    url: url,
    headers: authHeaderPublic(),
  };

  return axios(options);
}

function loadDetailChart(deviceId, startDate, endDate) {
  const url = `${API_URL}/iot/sensors/${deviceId}/values?start=${startDate}&end=${endDate}&comparemode=1`;
  const options = {
    method: 'get',
    url: url,
    headers: authHeaderPublic(),
  };

  return axios(options);
}

/**
 * Calls API to login
 *
 */

function saveData(data) {
  const url = `${API_URL}/iot/values/create`;
  return request
    .post(url)
    .set('Accept', 'application/json')
    .set(authHeaderPublic())
    .send(data)
    ;
}

function loadDetail(id) {
  const url = `${API_URL}/iot/values/${id}`;
  const options = {
    method: 'get',
    url: url,
    headers: authHeaderPublic(),
  };

  return axios(options);
}
