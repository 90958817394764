// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################
let dashboardRoutes = [
  {
    "dashboard": {
      "path": {
        "sk": "/sk/nastenka-kancelaria",
        "cs": "/cs/nastenka",
        "en": "/en/dashboard",
        "de": "/de/dashboard",
        "ru": "/ru/"
      },
      "translationId": "Common.dashboard",
      "icon": "DashboardIcon",
      "component": "DashboardPage",
      "leastPermission": ""
    },
    "orders": {
      "path": {
        "sk": "/sk/zakazky",
        "cs": "/cs/zakazky",
        "en": "/en/orders",
        "de": "/de/bestellungen",
        "ru": "/ru/"
      },
      "translationId": "Common.orders",
      "icon": "ZakazkyIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleOrders"
    },
    "vehicles": {
      "path": {
        "sk": "/sk/evidencia-vozidiel",
        "cs": "/cs/evidence-vozu",
        "en": "/en/vehicles",
        "de": "/de/autos",
        "ru": "/ru/avtomobil"
      },
      "translationId": "Common.vehicles",
      "icon": "VozidlaIcon",
      "component": "VehiclesPage",
      "leastPermission": "CanReadModuleVehicles"
    },
    "customers": {
      "path": {
        "sk": "/sk/zakaznici",
        "cs": "/cs/zakaznici",
        "en": "/en/customers",
        "de": "/de/kunden",
        "ru": "/ru/"
      },
      "translationId": "Common.customers",
      "icon": "ZakazniciIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleCustomers"
    },
    "calendar": {
      "path": {
        "sk": "/sk/kalendar",
        "cs": "/cs/kalendar",
        "en": "/en/calendar",
        "de": "/de/kalender",
        "ru": "/ru/"
      },
      "translationId": "Common.calendar",
      "icon": "KalendarIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleCalendar"
    },
    "users": {
      "path": {
        "sk": "/sk/uzivatelia",
        "cs": "/cs/uzivatele",
        "en": "/en/users",
        "de": "/de/benutzer",
        "ru": "/ru/пользователи"
      },
      "translationId": "Common.users",
      "icon": "UzivateliaIcon",
      "component": "UserProfile",
      "leastPermission": "CanReadModuleUsers"
    },
    "attendance": {
      "path": {
        "sk": "/sk/dochadzka",
        "cs": "/cs/dochazka",
        "en": "/en/attendance",
        "de": "/de/anwesenheit",
        "ru": "/ru/"
      },
      "translationId": "Common.attendance",
      "icon": "AttendanceIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleAttendance"
    },
    "service-book": {
      "path": {
        "sk": "/sk/servisna-knizka",
        "cs": "/cs/servisni-knizka",
        "en": "/en/service-book",
        "de": "/de/gesangbuch",
        "ru": "/ru/"
      },
      "translationId": "Common.serviceBook",
      "icon": "ServisnaKnizkaIcon",
      "component": "UnderConstructionPageServiceBook",
      "leastPermission": ""
    },
    "tire-storage": {
      "path": {
        "sk": "/sk/uschovna",
        "cs": "/cs/uschovna",
        "en": "/en/tire-storage",
        "de": "/de/reifen-lagerung",
        "ru": "/ru/"
      },
      "translationId": "Common.tireStorage",
      "icon": "UschovnaIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleTireStorage"
    },
    "replacement-parts": {
      "path": {
        "sk": "/sk/nahradne-diely",
        "cs": "/cs/nahradni-dily",
        "en": "/en/replacement-parts",
        "de": "/de/ersatzteile",
        "ru": "/ru/"
      },
      "translationId": "Common.replacementParts",
      "icon": "NahradneDielyIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleReplacementParts"
    },
    "inventory": {
      "path": {
        "sk": "/sk/inventar",
        "cs": "/cs/inventar",
        "en": "/en/inventory",
        "de": "/de/inventar",
        "ru": "/ru/инвентарь"
      },
      "translationId": "Common.inventory",
      "icon": "InventarIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleInventory"
    },
    "rooms": {
      "path": {
        "sk": "/sk/priestory",
        "cs": "/cs/prostory",
        "en": "/en/rooms",
        "de": "/de/raume",
        "ru": "/ru/"
      },
      "translationId": "Common.rooms",
      "icon": "PriestoryIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleRooms"
    },
    "diagnostics": {
      "path": {
        "sk": "/sk/diagnostika",
        "cs": "/cs/diagnostika",
        "en": "/en/diagnostics",
        "de": "/de/fehlerdiagnose",
        "ru": "/ru/"
      },
      "translationId": "Common.diagnostics",
      "icon": "DiagnostikaIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleDiagnostics"
    },
    "waitlist": {
      "path": {
        "sk": "/sk/poradovnik",
        "cs": "/cs/poradovnik",
        "en": "/en/waitlist",
        "de": "/de/warteliste",
        "ru": "/ru/"
      },
      "translationId": "Common.waitlist",
      "icon": "WaitlistIcon",
      "component": "UnderConstructionPage",
      "leastPermission": "CanReadModuleWaitlist"
    },
    "permissions": {
      "path": {
        "sk": "/sk/opravnenia",
        "cs": "/cs/opravneni",
        "en": "/en/permissions",
        "de": "/de/berechtigungen",
        "ru": "/ru/разрешений"
      },
      "translationId": "Common.permissions",
      "icon": "PermissionsIcon",
      "component": "PermissionsPage",
      "leastPermission": "CanReadModulePermissions"
    },
    "claims": {
      "path": {
        "sk": "/sk/reklamacie",
        "cs": "/cs/reklamace",
        "en": "/en/claims",
        "de": "/de/beschwerde",
        "ru": "/ru/жалоба"
      },
      "translationId": "Common.claims",
      "icon": "ClaimsIcon",
      "component": "ClaimsPage",
      "leastPermission": "CanReadModuleClaims"
    },
    "post": {
      "path": {
        "sk": "/sk/posta",
        "cs": "/cs/posta",
        "en": "/en/post",
        "de": "/de/post",
        "ru": "/ru/почта"
      },
      "translationId": "Common.post",
      "icon": "PostIcon",
      "component": "PostPage",
      "leastPermission": "CanReadModulePost"
    },
    "chat": {
      "path": {
        "sk": "/sk/chat",
        "cs": "/cs/chat",
        "en": "/en/chat",
        "de": "/de/chat",
        "ru": "/ru/chat"
      },
      "translationId": "Common.chat",
      "icon": "ChatIcon",
      "component": "ChatPage",
      "leastPermission": "CanReadModuleChat"
    },
    "iot-devices": {
      "path": {
        "sk": "/sk/iot-devices",
        "cs": "/cs/iot-devices",
        "en": "/en/iot-devices",
        "de": "/de/iot-devices",
        "ru": "/ru/iot-devices"
      },
      "translationId": "Common.iotDevices",
      "icon": "IotDevicesIcon",
      "component": "IotDevicesPage",
      "leastPermission": "CanReadModuleIotDevices"
    },
    "iot-values": {
      "path": {
        "sk": "/sk/iot-values",
        "cs": "/cs/iot-values",
        "en": "/en/iot-values",
        "de": "/de/iot-values",
        "ru": "/ru/iot-values"
      },
      "translationId": "Common.iotValues",
      "icon": "IotValuesIcon",
      "component": "IotValuesPage",
      "leastPermission": "CanReadModuleIotValues"
    },
    "support": {
      "path": {
        "sk": "/sk/pomocnik",
        "cs": "/cs/support",
        "en": "/en/support",
        "de": "/de/support",
        "ru": "/ru/support"
      },
      "translationId": "Common.support",
      "icon": "IotValuesIcon",
      "component": "SupportPage",
      "leastPermission": "CanReadModuleSupport"
    },
    "evrc": {
      "path": {
        "sk": "/sk/evrc",
        "cs": "/cs/evrc",
        "en": "/en/evrc",
        "de": "/de/evrc",
        "ru": "/ru/evrc"
      },
      "translationId": "Common.evrc",
      "icon": "EvrcIcon",
      "component": "EvrcPage",
      "leastPermission": "CanReadModuleEvrc"
    },
    "drive": {
      "path": {
        "sk": "/sk/drive",
        "cs": "/cs/drive",
        "en": "/en/drive",
        "de": "/de/drive",
        "ru": "/ru/drive"
      },
      "translationId": "Common.drive",
      "icon": "DriveIcon",
      "component": "DrivePage",
      "leastPermission": "CanReadModuleDrive"
    },
    "assets": {
      "path": {
        "sk": "/sk/tovar",
        "cs": "/cs/tovar",
        "en": "/en/assets",
        "de": "/de/assets",
        "ru": "/ru/assets"
      },
      "translationId": "Common.assets",
      "icon": "AssetsIcon",
      "component": "AssetsPage",
      "leastPermission": "CanReadModuleAssets"
    },
    "services": {
      "path": {
        "sk": "/sk/sluzby",
        'cs': '/cs/sluzby',
        'en': '/en/services',
        'de': '/de/services',
        'ru': '/ru/services'
      },
      'translationId': 'Common.services',
      'icon': 'ServicesIcon',
      'component': 'ServicesPage',
      'leastPermission': 'CanReadModuleServices'
    },
    'albums': {
      'path': {
        'sk': '/sk/albumy',
        'cs': '/cs/albumy',
        'en': '/en/albums',
        'de': '/de/albums',
        'ru': '/ru/albums'
      },
      'translationId': 'Common.albums',
      'icon': 'AlbumsIcon',
      'component': 'AlbumsPage',
      'leastPermission': 'CanReadModuleAlbums'
    },
  }
];

module.exports = {
  dashboardRoutes
};
