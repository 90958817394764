/* eslint-disable */
import React, {Suspense} from "react";
import PropTypes from "prop-types";
import {Switch, Route, Redirect} from "react-router-dom";
import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import dashboardRoutes from "../../routes/dashboard.jsx";
import dashboardStyle from '../../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { LANG } from '../../constants';
import { routes } from '../../variables/general';
import layout from '../../variables/layouts';

import { PrivateRoute } from '../../routes/PrivateRoute';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getProjectNameFromUrl } from '../../functions';
import { layout as carsis } from '../../variables/layouts/carsis.layout';
import { layout as personas } from '../../variables/layouts/personas.layout';
import { layout as therooms } from '../../variables/layouts/therooms.layout';
import { layout as iot } from '../../variables/layouts/iot.layout';
import { layout as webased } from '../../variables/layouts/webased.layout';

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path[LANG]} to={prop.to} key={key}/>;
      //return <Route path={prop.path[LANG]} component={prop.component} key={key}/>;
      return <PrivateRoute path={prop.path[LANG]} component={prop.component} key={key}/>;
    })}
  </Switch>

);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: 'carsis',
      mobileOpen: false,
      dashboardRoutes: [],
      error: {}
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  getRoutes = () => {
    this.setState({dashboardRoutes: routes});
  };

  handleDrawerToggle = () => {
    this.setState({mobileOpen: !this.state.mobileOpen});
  };

  getRoute() {
    return this.props.location.pathname !== "/maps";
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({mobileOpen: false});
    }
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener('resize', this.resizeFunction);
    this.getRoutes();

    //this.state.project = 'carsis';

    let project = getProjectNameFromUrl();
    let title = '';
    let favicon = '';

    switch (true) {
      case project === carsis.name:
        title = carsis.name;
        favicon = carsis.header.favicon;
        break;
      case project === personas.name:
        title = personas.name;
        favicon = personas.header.favicon;
        break;
      case project === therooms.name:
        title = therooms.name;
        favicon = therooms.header.favicon;
        break;
      case project === iot.name:
        title = iot.name;
        favicon = iot.header.favicon;
        break;
      case project === webased.name:
        title = webased.name;
        favicon = webased.header.favicon;
        break;
      default:
        title = 'carsis';

    }
    document.title = title;

    let link = document.querySelector('link[rel="shortcut icon"]') ||
      document.querySelector('link[rel="icon"]');

    if (!link) {
      link = document.createElement('link');
      link.id = 'favicon';
      link.rel = 'shortcut icon';
      document.head.appendChild(link);
    }

    link.href = favicon;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({mobileOpen: false});
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() {
    const {classes, ...rest} = this.props;
    let {dashboardRoutes} = this.state;
    return (
      <div className={classes.wrapper} style={{
        backgroundColor: layout.mainPanel.context.backgroundColor,
      }}>
        <Sidebar
          routes={dashboardRoutes}
          logoText={layout.header.projectName}
          logo={layout.header.logo}
          image={layout.sidebar.background}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="red"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel"
             style={{
               background: `url(${layout.mainPanel && layout.mainPanel.background ? layout.mainPanel.background : ''})`,
               backgroundSize: 'cover'
             }}>
          <Suspense fallback={<LinearProgress/>}>
            <Header
              routes={dashboardRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>
                  {switchRoutes}
                </div>
              </div>
            ) : (
              <div className={classes.map}>
                {switchRoutes}
              </div>
            )}
            {this.getRoute() ?
              null : <Footer/>}
          </Suspense>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(App);
