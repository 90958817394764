export const vehiclesConstants = {
  VEHICLES_SET_SUBMENU: 'VEHICLES_SET_SUBMENU',

  VEHICLES_LOAD_DATA_REQUEST: 'VEHICLES_LOAD_DATA_REQUEST',
  VEHICLES_LOAD_DATA_SUCCESS: 'VEHICLES_LOAD_DATA_SUCCESS',
  VEHICLES_LOAD_DATA_FAILURE: 'VEHICLES_LOAD_DATA_FAILURE',

  VEHICLES_LOAD_DETAIL_SUCCESS: 'VEHICLES_LOAD_DETAIL_SUCCESS',
  VEHICLES_LOAD_DETAIL_FAILURE: 'VEHICLES_LOAD_DETAIL_FAILURE',

  VEHICLES_SAVE_DATA_SUCCESS: 'VEHICLES_SAVE_DATA_SUCCESS',
  VEHICLES_SAVE_DATA_FAILURE: 'VEHICLES_SAVE_DATA_FAILURE',

  VEHICLES_RESET_DETAIL_SUCCESS: 'VEHICLES_RESET_DETAIL_SUCCESS',

  VEHICLES_LOAD_SELECTED_SUCCESS: 'VEHICLES_LOAD_SELECTED_SUCCESS',
  VEHICLES_ADD_TO_SELECTED: 'VEHICLES_ADD_TO_SELECTED',

  VEHICLES_SET_DETAIL_ID: 'VEHICLES_SET_DETAIL_ID',
};