export const chatConstants = {
  CHAT_SET_SUBMENU: 'CHAT_SET_SUBMENU',

  CHAT_LOAD_DATA_REQUEST: 'CHAT_LOAD_DATA_REQUEST',
  CHAT_LOAD_DATA_SUCCESS: 'CHAT_LOAD_DATA_SUCCESS',
  CHAT_LOAD_DATA_FAILURE: 'CHAT_LOAD_DATA_FAILURE',

  CHAT_LOAD_DETAIL_SUCCESS: 'CHAT_LOAD_DETAIL_SUCCESS',
  CHAT_LOAD_DETAIL_FAILURE: 'CHAT_LOAD_DETAIL_FAILURE',

  CHAT_SET_DETAIL_ID: 'CHAT_SET_DETAIL_ID',
};