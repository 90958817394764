import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  CartesianAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Brush
} from 'recharts';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useForm, Controller } from 'react-hook-form';
import { getIdFromUrl } from '../../../functions';
import { iotValuesService } from '../../../services/public.iotValues.service';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import {layout} from '../../../variables/layouts/carsis.layout';

const TsensorChart = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: 'white'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();
  const [details, setDetails] = useState({
      startDate: moment().subtract(24, 'hours').format('YYYY-MM-DDTHH:mm'),
      endDate: moment().format('YYYY-MM-DDTHH:mm')
    }
  );
  const [chartData, setChartData] = useState([]);
  const [color, setColor] = useState('#8884d8');
  const [loading, setLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState();
  const [startDate, setStartDate] = useState(moment().subtract(24, 'hours'));
  const [endDate, setEndDate] = useState(moment());

  const {register, watch, handleSubmit, setValue, getValues} = useForm();

  useEffect(() => {
    let id = getIdFromUrl();
    if (id) {
      iotValuesService.loadDetailChart(id, details.startDate, details.endDate)
        .then((result) => {
          const data = result.data;
          setChartData(data);
          setColor('#8884d8');
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [details]);

  const onSubmit = data => {
    setDetails(data);
    setColor('#bbb');
    setLoading(true);
  };

  const drp = () => (
    <Grid
      item
      xs='12'
      md={5}
    >
      <DateRangePicker
        startDateId="startDate"
        endDateId="endDate"
        startDate={startDate}
        endDate={endDate}
        onDatesChange={({startDate, endDate}) => {
          setStartDate(startDate);
          setEndDate(endDate);
          console.log(startDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput);
        }}
      />
    </Grid>);

  const TiltedAxisTick = (props) => {

    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-45)">
          {moment(payload.value).format('HH:mm')}
        </text>
      </g>
    );

  };
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate style={{width: '100%'}}>
        <Grid container spacing={3} justify={'flex-end'}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              item
              xs={12}
              md={3}
              container
            >
              <TextField
                id='startDate'
                name='startDate'
                label={<FormattedMessage id='startDate'
                                         defaultMessage='startDate'/>}
                type="datetime-local"
                defaultValue={details.startDate}
                className={classes.textField}
                fullWidth
                multiline={false}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
            >
              <TextField
                id='endDate'
                name='endDate'
                label={<FormattedMessage id='endDate'
                                         defaultMessage='endDate'/>}
                type="datetime-local"
                defaultValue={details.endDate}
                className={classes.textField}
                fullWidth
                style={{width: '100%'}}
                multiline={false}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
            >
              <Button variant="outlined" color="primary" disabled={loading}
                      type="submit" style={{margin: '10px'}}>
                <FormattedMessage id={'Common.apply'}
                                  defaultMessage={`apply`}/>
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid
            container
            item
            xs={12}
            style={{width: '100%', height: 350}}
          >
            <ResponsiveContainer>
              <AreaChart
                data={chartData}
                margin={{
                  top: 10, right: 0, left: 30, bottom: 40,
                }}
              >
                <CartesianAxis tickSize={24}/>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date" height={50} label={{ value: 'Dátum', angle: 0, position: 'bottom' }}
                       tickFormatter={value => value} tick={<TiltedAxisTick />}/>
                <YAxis type="number" domain={['dataMin - 5', 'dataMax + 5']} tickCount={6}
                       label={{ value: 'Teplota v °C', angle: -90, position: 'left' }} tickFormatter={value => value.toFixed(1)}/>
                <Tooltip/>
                <Area type="monotone" dataKey="selvalue" stroke={color} fill={color}/>
                <Area type="monotone" dataKey="prevvalue" stroke={layout.colors.infoColor} fill={layout.colors.infoColor}/>
              </AreaChart>
            </ResponsiveContainer>
            <Backdrop className={classes.backdrop} open={loading} timeout={500} onClick={() => setLoading(true)}>
              <CircularProgress color="inherit"/>
            </Backdrop>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default TsensorChart;

