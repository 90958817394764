import Logo from '../../assets/img/therooms-logo.jpg';
import Background from '../../assets/img/therooms.jpg';
import red from '@material-ui/core/colors/red';
import deepOrange from '@material-ui/core/colors/deepOrange';

export const layout = {
  name: 'therooms',
  palette: {
    primary: red,
    secondary: deepOrange,
    type: 'dark',
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  colors: {
    primaryColor: red,
    secondaryColor: '#3C3C3C',
    warningColor: "#ff9800",
    dangerColor: '#b71c1c',
    successColor: "#4caf50",
    infoColor: '#000',
    infoColorShadow: 'rgba(244, 67, 54, 0.28)',
    greyColor: "#22303A",
  },
  header: {
    projectName: 'The Rooms',
    logo: Logo,
    favicon: Logo,
    color: 'red',
    backgroundColor: '#b71c1c',
  },
  sidebar: {
    overlayColor: '#292929',
    background: '',
    borderLeft: '1px solid #580000',
  },
  mainPanel: {
    background: Background,
    paper: {
      backgroundColor: 'rgba(0,0,0,.7)',
      color: 'rgba(255,255,255,.7)',
      border: '1px solid #b71c1c',
    },
    submenu: {
      backgroundColor: 'rgba(0,0,0,.7)',
      textColor: '',
    },
    context: {
      backgroundColor: 'rgba(0,0,0,.8)',
    }
  },
  cover: {
    background: Background
  }
};
