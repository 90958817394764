import {historyConstants} from '../constants/history.constants';

export function history(state = {
  data: [],
  error: {},
  isSubmitting: false,
}, action) {
  switch (action.type) {
    case historyConstants.HISTORY_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case historyConstants.HISTORY_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.history,
        data: action.data
      };
    case historyConstants.HISTORY_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.history,
        error: action.error
      };
    default:
      return state
  }
}