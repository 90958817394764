export const assetsConstants = {
  ASSETS_SET_SUBMENU: 'ASSETS_SET_SUBMENU',

  ASSETS_LOAD_DATA_REQUEST: 'ASSETS_LOAD_DATA_REQUEST',
  ASSETS_LOAD_DATA_SUCCESS: 'ASSETS_LOAD_DATA_SUCCESS',
  ASSETS_LOAD_DATA_FAILURE: 'ASSETS_LOAD_DATA_FAILURE',

  ASSETS_LOAD_DETAIL_SUCCESS: 'ASSETS_LOAD_DETAIL_SUCCESS',
  ASSETS_LOAD_DETAIL_FAILURE: 'ASSETS_LOAD_DETAIL_FAILURE',

  ASSETS_SAVE_DATA_SUCCESS: 'ASSETS_SAVE_DATA_SUCCESS',
  ASSETS_SAVE_DATA_FAILURE: 'ASSETS_SAVE_DATA_FAILURE',

  ASSETS_RESET_DETAIL_SUCCESS: 'ASSETS_RESET_DETAIL_SUCCESS',

  ASSETS_SET_DETAIL_ID: 'ASSETS_SET_DETAIL_ID',
  ASSETS_ADD_TO_SELECTED: 'ASSETS_ADD_TO_SELECTED',
};