import {postConstants} from '../constants/post.constants';

export function Post(state = {}, action) {
  switch (action.type) {
    case postConstants.POST_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case postConstants.POST_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.Post,
        data: action.data
      };
    case postConstants.POST_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.Post,
        error: action.error
      };


    case postConstants.POST_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.Post,
        detail: action.detail
      };
    case postConstants.POST_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case postConstants.POST_SET_DETAIL_ID:
      return {
        ...state,
        ...state.Post,
        detailId: action.detailId
      };

    case postConstants.POST_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.Post,
        isSubmitting: action.isSubmitting
      };
    default:
      return state
  }
}