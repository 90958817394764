import {lazy} from "react";

const ListTab = lazy(() => import('../views/Vehicles/List.jsx'));
const DetailsTab = lazy(() => import('../views/Vehicles/Details.jsx'));
const HistoryTab = lazy(() => import('../views/Vehicles/History.jsx'));

export const vehiclesRoutes = [
  {
    path: {
      sk: "/sk/evidencia-vozidiel/zoznam",
      cs: "/cs/evidence-vozu/seznam",
      en: "/en/vehicles/list",
      de: "/de/autos/liste",
      ru: "/ru/автомобиль/Список"
    },
    en: {
      path: "/en/vehicles/list",
      tab: "list",
      translationId: "Common.list",
    },
    sk: {
      path: "/sk/evidencia-vozidiel/zoznam",
      tab: "zoznam",
      translationId: "Common.list",
    },
    cs: {
      path: "/cs/evidence-vozu/seznam",
      tab: "seznam",
      translationId: "Common.list",
    },
    de: {
      path: "/de/autos/liste",
      tab: "liste",
      translationId: "Common.list",
    },
    ru: {
      path: "/ru/автомобиль/Список",
      tab: "Список",
      translationId: "Common.list",
    },
    translationId: "Common.list",
    component: ListTab,
    icon: "List",
    permission: `CanReadModuleVehiclesTabList`,
  },
  {
    path: {
      sk: "/sk/evidencia-vozidiel/detaily",
      cs: "/cs/evidence-vozu/detaily",
      en: "/en/vehicles/details",
      de: "/de/autos/detailen",
      ru: "/ru/автомобиль/подробности"
    },
    en: {
      path: "/en/vehicles/details",
      tab: "details",
      translationId: "Common.details",
    },
    sk: {
      path: "/sk/evidencia-vozidiel/detaily",
      tab: "detaily",
      translationId: "Common.details",
    },
    cs: {
      path: "/cs/evidence-vozu/detaily",
      tab: "detaily",
      translationId: "Common.details",
    },
    de: {
      path: "/de/autos/detailen",
      tab: "detailen",
      translationId: "Common.details",
    },
    ru: {
      path: "/ru/автомобиль/подробности",
      tab: "подробности",
      translationId: "Common.details",
    },
    translationId: "Common.details",
    component: DetailsTab,
    icon: "Details",
    permission: `CanReadModuleVehiclesTabDetails`,
  },
  {
    path: {
      sk: "/sk/evidencia-vozidiel/historia",
      cs: "/cs/evidence-vozu/historie",
      en: "/en/vehicles/history",
      de: "/de/autos/historie",
      ru: "/ru/автомобиль/история"
    },
    en: {
      path: "/en/vehicles/history",
      tab: "history",
      translationId: "Common.history",
    },
    sk: {
      path: "/sk/evidencia-vozidiel/historia",
      tab: "historia",
      translationId: "Common.history",
    },
    cs: {
      path: "/cs/evidence-vozu/historie",
      tab: "historie",
      translationId: "Common.history",
    },
    de: {
      path: "/de/autos/historie",
      tab: "historie",
      translationId: "Common.history",
    },
    ru: {
      path: "/ru/автомобиль/история",
      tab: "история",
      translationId: "Common.history",
    },
    translationId: "Common.history",
    component: HistoryTab,
    icon: "History",
    permission: `CanReadModuleVehiclesTabHistory`,
  },
];

export default vehiclesRoutes;
