import { albumsConstants } from '../constants/albums.constants';

export function Albums(state = {}, action) {
  switch (action.type) {
    case albumsConstants.DRIVE_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case albumsConstants.DRIVE_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.Albums,
        data: action.data
      };
    case albumsConstants.DRIVE_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.Albums,
        error: action.error
      };

    case albumsConstants.DRIVE_SET_SUBMENU:
      return {
        ...state,
        ...state.Albums,
        submenu: action.submenu
      };
    default:
      return state;
  }
}
