import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuIcon from "@material-ui/icons/Menu";
import LangSelector from "./HeaderLinks.jsx";
import Profile from "./Profile.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Logout from "@material-ui/icons/MeetingRoom";
import {FormattedMessage} from 'react-intl';
import headerStyle from "../../assets/jss/material-dashboard-react/components/headerStyle.jsx";
import * as userService from "../../services/user.service";
import {connect} from "react-redux";
import {LANG} from '../../constants';

function Header({...props}) {
  function moduleTitle() {

    props.routes.map((prop, key) => {
      if (prop.path[LANG] === props.location.pathname) {
        let route = JSON.stringify(prop);
        localStorage.setItem('route', route);
      }
      return null;
    });

    let translationId = props.common && props.common.module ? 'Common.' + props.common.module.toLowerCase() : 'Common.dashboard';

    return <FormattedMessage id={translationId ? translationId : 'Common'} defaultMessage={props.common.module}/>;
  }

  const {classes, color} = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });

  const handleLogout = () => {
    userService.logout();
  };

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classes.title}>
            {moduleTitle()}
          </Button>
        </div>
        <Profile/>
        <LangSelector/>
        <Button
          color="transparent"
          justIcon={true}
          simple={true}
          aria-label="Person"
          className={classes.buttonLink}
          onClick={handleLogout}
        >
          <Logout className={classes.icons}/>
        </Button>
        <Hidden smUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon/>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

const mapStateToProps = state => ({
  common: state.common,
});

export default connect(mapStateToProps, null)(withStyles(headerStyle)(Header));
