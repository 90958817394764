import {vehiclesConstants} from '../constants/vehicles.constants';

export function Vehicles(state = {
  selected: []
}, action) {
  switch (action.type) {
    case vehiclesConstants.VEHICLES_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case vehiclesConstants.VEHICLES_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.Vehicles,
        data: action.data
      };
    case vehiclesConstants.VEHICLES_LOAD_DATA_FAILURE:
      return {
        error: action.error
      };

    case vehiclesConstants.VEHICLES_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.Vehicles,
        detail: action.detail
      };
    case vehiclesConstants.VEHICLES_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case vehiclesConstants.VEHICLES_RESET_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.Vehicles,
        detail: action.detail
      };

    case vehiclesConstants.VEHICLES_LOAD_SELECTED_SUCCESS:
      return {
        ...state,
        ...state.Vehicles,
        selected: action.data
      };

    case vehiclesConstants.VEHICLES_ADD_TO_SELECTED:
      let count = state.selected && state.selected.length;
      //state && state.selected && action.item ? state.selected.filter(item => item.id === action.item.id) : [];
      if (count === 0) {
        return {
          ...state,
          ...state.Vehicles,
          selected: state && state.selected ? [...state.selected, action.item] : [action.item]
        };
      } else {
        return {
          ...state,
          ...state.Vehicles,
          ...state.selected
        };
      }

    case vehiclesConstants.VEHICLES_SET_SUBMENU:
      return {
        ...state,
        ...state.Vehicles,
        submenu: action.submenu
      };

    case vehiclesConstants.VEHICLES_SET_DETAIL_ID:
      return {
        ...state,
        ...state.Vehicles,
        detailId: action.detailId
      };

    case vehiclesConstants.VEHICLES_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.Vehicles,
        isSubmitting: false
      };
    default:
      return state
  }
}