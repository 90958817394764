import {
  container,
  drawerWidth,
  drawerMiniWidth,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "../../../../assets/jss/material-dashboard-react.jsx";
import layout from '../../../../variables/layouts';

const headerStyle = theme => ({
  appBar: {
    backgroundColor: layout.header && layout.header.backgroundColor ? layout.header.backgroundColor : primaryColor,
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "fixed",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: "#fff",
    border: "0",
    borderRadius: "0",
    padding: "7px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  container: {
    ...container,
    minHeight: "50px"
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    position: 'relative',
    [theme.breakpoints.up("lg")]: {
      left: drawerWidth,
    },
    [theme.breakpoints.down("md")]: {
      left: drawerMiniWidth,
    },
    [theme.breakpoints.down("xs")]: {
      left: 0,
    },
    left: drawerWidth,
    lineHeight: "30px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  appResponsive: {
    top: "8px"
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    ...defaultBoxShadow
  }
});

export default headerStyle;
