export const postConstants = {
  POST_LOAD_DATA_REQUEST: 'POST_LOAD_DATA_REQUEST',
  POST_LOAD_DATA_SUCCESS: 'POST_LOAD_DATA_SUCCESS',
  POST_LOAD_DATA_FAILURE: 'POST_LOAD_DATA_FAILURE',

  POST_SAVE_DATA_SUCCESS: 'POST_SAVE_DATA_SUCCESS',
  POST_SAVE_DATA_FAILURE: 'POST_SAVE_DATA_FAILURE',

  POST_LOAD_DETAIL_SUCCESS: 'POST_LOAD_DETAIL_SUCCESS',
  POST_LOAD_DETAIL_FAILURE: 'POST_LOAD_DETAIL_FAILURE',

  POST_SET_DETAIL_ID: 'POST_SET_DETAIL_ID',
};