import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  container
} from "../../../../assets/jss/material-dashboard-react.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "50px",
    padding: "17px 0px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  mainContent: {
    position: 'relative',
    top: '74px',
  },
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
