import {evrcConstants} from '../constants/evrc.constants';

export function Evrc(state = {}, action) {
  switch (action.type) {
    case evrcConstants.EVRC_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case evrcConstants.EVRC_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.evrc,
        data: action.data
      };
    case evrcConstants.EVRC_LOAD_DATA_FAILURE:
      return {
        error: action.error
      };

    case evrcConstants.EVRC_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.evrc,
        detail: action.detail
      };
    case evrcConstants.EVRC_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case evrcConstants.EVRC_RESET_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.evrc,
        detail: action.detail
      };

    case evrcConstants.EVRC_ADD_TO_SELECTED:
      let count = state.selected && state.selected.length;
      //state && state.selected && action.item ? state.selected.filter(item => item.id === action.item.id) : [];
      if (count === 0) {
        return {
          ...state,
          ...state.evrc,
          selected: state && state.selected ? [...state.selected, action.item] : [action.item]
        };
      } else {
        return {
          ...state,
          ...state.evrc,
          ...state.selected
        };
      }

    case evrcConstants.EVRC_SET_SUBMENU:
      return {
        ...state,
        ...state.evrc,
        submenu: action.submenu
      };

    case evrcConstants.EVRC_SET_DETAIL_ID:
      return {
        ...state,
        ...state.evrc,
        detailId: action.detailId
      };

    case evrcConstants.EVRC_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.evrc,
        isSubmitting: action.isSubmitting
      };
    default:
      return state
  }
}