import {escapeConstants} from '../constants/escape.constants';

export function EscapeRooms(state = {}, action) {
  switch (action.type) {
    case escapeConstants.ESCAPE_LOAD_DATA_REQUEST:
      return {
        loading: true
      };
    case escapeConstants.ESCAPE_LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...state.Users,
        data: action.data
      };
    case escapeConstants.ESCAPE_LOAD_DATA_FAILURE:
      return {
        ...state,
        ...state.Users,
        error: action.error
      };


    case escapeConstants.ESCAPE_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        ...state.Users,
        detail: action.detail
      };
    case escapeConstants.ESCAPE_LOAD_DETAIL_FAILURE:
      return {
        error: action.error
      };

    case escapeConstants.ESCAPE_SET_DETAIL_ID:
      return {
        ...state,
        ...state.Users,
        detailId: action.detailId
      };

    case escapeConstants.ESCAPE_SAVE_DATA_SUCCESS:
      return {
        ...state,
        ...state.Users,
        isSubmitting: action.isSubmitting
      };
    default:
      return state
  }
}