import request from 'superagent';
import {API_URL} from '../constants';
import { authHeaderPublic } from '../helpers';
import axios from 'axios';

/**
 * Calls API to get Iot Devices
 */

export const iotGroupServicePublic = {
  loadDetail,
  loadGroups
};

function loadDetail(id) {
  const url = `${API_URL}/iot/groups/${id}`;
  const options = {
    method: 'get',
    url: url,
    headers: authHeaderPublic(),
  };

  return axios(options);
}

function loadGroups() {
  let url = JSON.parse(localStorage.getItem('public'));

  if (url && url.Groups) {
    return url.Groups;
  } else {
    return [];
  }
}
