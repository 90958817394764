import request from 'superagent';
import {API_URL} from '../constants';
import {authHeader} from '../helpers';

/**
 * Calls API to get Admin
 */

export const adminService = {
  loadDataTable,
  switchCompany,
};

function loadDataTable() {
  const url = `${API_URL}/admin/company`;
  return request
    .get(url)
    .set('Accept', 'application/json')
    .set(authHeader());
}

/**
 * Calls API to login
 *
 */
function switchCompany(companyId) {
  const url = `${API_URL}/admin/switchcompany/${companyId}`;
  return request
    .get(url)
    .set('Accept', 'application/json')
    .set(authHeader())
    ;
}

