import {lazy} from "react";

import Dashboard from "@material-ui/icons/Dashboard";
import UzivateliaIcon from "@material-ui/icons/GroupAdd";
import ServisnaKnizkaIcon from "@material-ui/icons/Assignment";
import ZakazkyIcon from "@material-ui/icons/Build";
import ZakazniciIcon from "@material-ui/icons/Person";
import VozidlaIcon from "@material-ui/icons/DirectionsCar";
import KalendarIcon from "@material-ui/icons/Today";
import UschovnaIcon from "@material-ui/icons/Toll";
import PriestoryIcon from "@material-ui/icons/AccountBalance";
import DiagnostikaIcon from "@material-ui/icons/MyLocation";
import InventarIcon from "@material-ui/icons/Ballot";
import NahradneDielyIcon from "@material-ui/icons/ShoppingCart";
import AttendanceIcon from '@material-ui/icons/TransferWithinAStation';
import WaitlistIcon from '@material-ui/icons/Timelapse';
import PermissionsIcon from '@material-ui/icons/Security';
import ClaimsIcon from '@material-ui/icons/SyncProblem';
import EvrcIcon from '@material-ui/icons/Payment';
import PostIcon from '@material-ui/icons/Mail';
import ChatIcon from '@material-ui/icons/Chat';
import EscapeRoomsIcon from '@material-ui/icons/DirectionsRun';
import IotDevicesIcon from '@material-ui/icons/DirectionsRun';
import IotValuesIcon from '@material-ui/icons/DirectionsRun';
import SupportIcon from '@material-ui/icons/Help';
import ServicesIcon from '@material-ui/icons/Build';
import AlbumsIcon from '@material-ui/icons/PhotoAlbum';
import icons from '../variables/icons';

const UnderConstructionPage = lazy(() => import("../views/UnderConstruction/UnderConstruction.jsx"));
const DashboardPage = lazy(() => import("../views/Dashboard/Dashboard.jsx"));
const UsersPage = lazy(() => import('../views/Users/Index'));
const VehiclesPage = lazy(() => import("../views/Vehicles/Index.jsx"));
const InventoryPage = lazy(() => import("../views/Inventory/Index.jsx"));
const WaitlistPage = lazy(() => import("../views/Waitlist/Index.jsx"));
const PermissionsPage = lazy(() => import("../views/Permissions/Index.jsx"));
const CustomersPage = lazy(() => import("../views/Customers/Index.jsx"));
const ClaimsPage = lazy(() => import("../views/Claims/Index.jsx"));
const OrdersPage = lazy(() => import('../views/Orders/Index.jsx'));
const EvrcPage = lazy(() => import('../views/Evrc/Index.jsx'));
const PostPage = lazy(() => import('../views/Post/Index.jsx'));
const ChatPage = lazy(() => import('../views/Chat/Index.jsx'));
const EscapeRoomsPage = lazy(() => import('../views/EscapeRooms/Index.jsx'));
const IotDevicesPage = lazy(() => import('../views/IotDevices/Index.jsx'));
const IotValuesPage = lazy(() => import('../views/IotValues/Index.jsx'));
const SupportPage = lazy(() => import('../views/Support/Index.jsx'));
const DrivePage = lazy(() => import('../views/Drive/Index.jsx'));
const AssetsPage = lazy(() => import('../views/Assets/Index.jsx'));
const ServicesPage = lazy(() => import('../views/Services/Index.jsx'));
const AlbumsPage = lazy(() => import('../views/Albums/Index.jsx'));

const dashboardRoutes = [
  {
    path: {
      sk: "/sk/nastenka-kancelaria",
      cs: "/cs/nastenka",
      en: "/en/dashboard",
      de: "/de/dashboard",
      ru: "/ru/",
    },
    translationId: "Common.dashboard",
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: {
      sk: "/sk/zakazky",
      cs: "/cs/zakazky",
      en: "/en/orders",
      de: "/de/bestellungen",
      ru: "/ru/",
    },
    translationId: "Common.orders",
    icon: ZakazkyIcon,
    component: OrdersPage
  },
  {
    path: {
      sk: "/sk/evidencia-vozidiel",
      cs: "/cs/evidence-vozu",
      en: "/en/vehicles",
      de: "/de/autos",
      ru: "/ru/avtomobil", // автомобиль
    },
    translationId: "Common.vehicles",
    icon: VozidlaIcon,
    component: VehiclesPage
  },
  {
    path: {
      sk: "/sk/zakaznici",
      cs: "/cs/zakaznici",
      en: "/en/customers",
      de: "/de/kunden",
      ru: "/ru/",
    },
    translationId: "Common.customers",
    icon: ZakazniciIcon,
    component: CustomersPage
  },
  {
    path: {
      sk: "/sk/kalendar",
      cs: "/cs/kalendar",
      en: "/en/calendar",
      de: "/de/kalender",
      ru: "/ru/",
    },
    translationId: "Common.calendar",
    icon: KalendarIcon,
    component: UnderConstructionPage
  },
  {
    path: {
      sk: "/sk/uzivatelia",
      cs: "/cs/uzivatele",
      en: "/en/users",
      de: "/de/benutzer",
      ru: "/ru/пользователи",
    },
    translationId: "Common.users",
    icon: UzivateliaIcon,
    component: UsersPage,
  },
  {
    path: {
      sk: "/sk/dochadzka",
      cs: "/cs/dochazka",
      en: "/en/attendance",
      de: "/de/anwesenheit",
      ru: "/ru/",
    },
    translationId: "Common.attendance",
    icon: AttendanceIcon,
    component: UnderConstructionPage
  },
  {
    path: {
      sk: "/sk/servisna-knizka",
      cs: "/cs/servisni-knizka",
      en: "/en/service-book",
      de: "/de/gesangbuch",
      ru: "/ru/",
    },
    translationId: "Common.serviceBook",
    icon: ServisnaKnizkaIcon,
    component: UnderConstructionPage
  },
  {
    path: {
      sk: "/sk/uschovna",
      cs: "/cs/uschovna",
      en: "/en/tire-storage",
      de: "/de/reifen-lagerung",
      ru: "/ru/",
    },
    translationId: "Common.tireStorage",
    icon: UschovnaIcon,
    component: UnderConstructionPage
  },
  {
    path: {
      sk: "/sk/nahradne-diely",
      cs: "/cs/nahradni-dily",
      en: "/en/replacement-parts",
      de: "/de/ersatzteile",
      ru: "/ru/",
    },
    translationId: "Common.replacementParts",
    icon: NahradneDielyIcon,
    component: UnderConstructionPage
  },
  {
    path: {
      sk: "/sk/inventar",
      cs: "/cs/inventar",
      en: "/en/inventory",
      de: "/de/inventar",
      ru: "/ru/инвентарь",
    },
    translationId: "Common.inventory",
    icon: InventarIcon,
    component: InventoryPage
  },
  {
    path: {
      sk: "/sk/priestory",
      cs: "/cs/prostory",
      en: "/en/rooms",
      de: "/de/raume",
      ru: "/ru/",
    },
    translationId: "Common.rooms",
    icon: PriestoryIcon,
    component: UnderConstructionPage
  },
  {
    path: {
      sk: "/sk/diagnostika",
      cs: "/cs/diagnostika",
      en: "/en/diagnostics",
      de: "/de/fehlerdiagnose",
      ru: "/ru/",
    },
    translationId: "Common.diagnostics",
    icon: DiagnostikaIcon,
    component: UnderConstructionPage
  },
  {
    path: {
      sk: "/sk/poradovnik",
      cs: "/cs/poradovnik",
      en: "/en/waitlist",
      de: "/de/warteliste",
      ru: "/ru/",
    },
    translationId: "Common.waitlist",
    icon: WaitlistIcon,
    component: WaitlistPage
  },
  {
    path: {
      sk: "/sk/opravnenia",
      cs: "/cs/opravneni",
      en: "/en/permissions",
      de: "/de/berechtigungen",
      ru: "/ru/разрешений"
    },
    translationId: "Common.permissions",
    icon: PermissionsIcon,
    component: PermissionsPage,
    leastPermission: "CanReadModulePermissions"
  },
  {
    path: {
      sk: "/sk/reklamacie",
      cs: "/cs/reklamace",
      en: "/en/claims",
      de: "/de/beschwerde",
      ru: "/ru/жалоба"
    },
    translationId: "Common.claims",
    icon: ClaimsIcon,
    component: ClaimsPage,
    leastPermission: "CanReadModuleClaims"
  },
  {
    path: {
      sk: "/sk/evrc",
      cs: "/cs/evrc",
      en: "/en/evrc",
      de: "/de/evrc",
      ru: "/ru/еврц"
    },
    translationId: "Common.evrc",
    icon: EvrcIcon,
    component: EvrcPage,
    leastPermission: "CanReadModuleEvrc"
  },
  {
    path: {
      sk: "/sk/posta",
      cs: "/cs/posta",
      en: "/en/post",
      de: "/de/post",
      ru: "/ru/почта"
    },
    translationId: "Common.post",
    icon: PostIcon,
    component: PostPage,
    leastPermission: "CanReadModulePost"
  },
  {
    path: {
      sk: "/sk/chat",
      cs: "/cs/chat",
      en: "/en/chat",
      de: "/de/chat",
      ru: "/ru/chat"
    },
    translationId: "Common.chat",
    icon: ChatIcon,
    component: ChatPage,
    leastPermission: "CanReadModuleChat"
  },
  {
    path: {
      sk: "/sk/escape",
      cs: "/cs/escape",
      en: "/en/escape",
      de: "/de/escape",
      ru: "/ru/escape"
    },
    translationId: "Common.escapeRooms",
    icon: EscapeRoomsIcon,
    component: EscapeRoomsPage,
    leastPermission: "CanReadModuleEscapeRooms"
  },
  {
    path: {
      sk: "/sk/iot-devices",
      cs: "/cs/iot-devices",
      en: "/en/iot-devices",
      de: "/de/iot-devices",
      ru: "/ru/iot-devices"
    },
    translationId: "Common.iotDevices",
    icon: IotDevicesIcon,
    component: IotDevicesPage,
    leastPermission: "CanReadModuleIotDevices"
  },
  {
    path: {
      sk: "/sk/iot-values",
      cs: "/cs/iot-values",
      en: "/en/iot-values",
      de: "/de/iot-values",
      ru: "/ru/iot-values"
    },
    translationId: "Common.iotValues",
    icon: IotValuesIcon,
    component: IotValuesPage,
    leastPermission: "CanReadModuleIotValues"
  },
  {
    path: {
      sk: "/sk/pomocnik",
      cs: "/cs/support",
      en: "/en/support",
      de: "/de/support",
      ru: "/ru/support"
    },
    translationId: "Common.support",
    icon: SupportIcon,
    component: SupportPage,
    leastPermission: "CanReadModuleSupport"
  },
  {
    path: {
      sk: "/sk/drive",
      cs: "/cs/drive",
      en: "/en/drive",
      de: "/de/drive",
      ru: "/ru/drive"
    },
    translationId: "Common.drive",
    icon: icons['DriveIcon'],
    component: DrivePage,
    leastPermission: "CanReadModuleDrive"
  },
  {
    path: {
      sk: "/sk/tovar",
      cs: "/cs/tovar",
      en: "/en/assets",
      de: "/de/assets",
      ru: "/ru/assets"
    },
    translationId: "Common.assets",
    icon: icons['AssetsIcon'],
    component: AssetsPage,
    leastPermission: "CanReadModuleAssets"
  },
  {
    path: {
      sk: "/sk/sluzby",
      cs: '/cs/sluzby',
      en: '/en/services',
      de: '/de/services',
      ru: '/ru/services'
    },
    translationId: 'Common.services',
    icon: icons['ServicesIcon'],
    component: ServicesPage,
    leastPermission: 'CanReadModuleServices'
  },
  {
    path: {
      sk: '/sk/albumy',
      cs: '/cs/albumy',
      en: '/en/albums',
      de: '/de/albums',
      ru: '/ru/albums'
    },
    translationId: 'Common.albums',
    icon: icons['AlbumsIcon'],
    component: AlbumsPage,
    leastPermission: 'CanReadModuleAlbums'
  },
];

export default dashboardRoutes;
